import apiClient, { getLocalToken } from '../../../utilities/apiClient';

interface SigninPayload {
  employee_id: string;
  password: string;
}
interface ForgetPassPayload {
  employee_id: string;
}

interface ResetPassPayload {
  token: string;
  password: string;
}

// Signin function
export const signin = (payload: SigninPayload) => {
  return apiClient.post(`${apiClient.Urls.signin}`, payload);
};

export const forgetPass = (payload: ForgetPassPayload) => {
  return apiClient.post(`${apiClient.Urls.forgetPass}`, payload);
};

export const resetPass = (payload: ResetPassPayload) => {
  return apiClient.post(`${apiClient.Urls.resetPass}`, payload);
};
