import Label from 'common/Label';
import deleteIcon from 'assets/icons/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createProjectCompound } from '../redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { Button, message, notification, Space, Spin, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import { useState } from 'react';
import { openNotification } from 'utilities/notification';
import { ReadOutlined } from '@ant-design/icons';
import Modal from 'common/Modal';
import { PERMISSIONS } from 'utilities/roleRights';
import { getLoggedInUser } from 'utilities/helpers';
import {
  TabTitle,
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';

type TProps = {
  id: number;
  compoundCode: string;
  // aliasCode: string;
  quantityRequested: string;
  purityRequested: string;
  compoundCodLength?: number;
  keySpecs: string;
};

const containerClasses = 'sm:gap-2';
const descriptionClasses =
  'truncate w-auto sm:w-[5.5rem] md:w-[7rem] lg:w-[5rem] xl:w-auto text-[10px] sm:text-xs text-tertiary-dark';
const labelClasses = ' text-tertiary-filter text-[10px] sm:text-xs font-bold';

export default function CompoundCard({
  id,
  compoundCode,
  // aliasCode,
  quantityRequested,
  purityRequested,
  compoundCodLength,
  keySpecs,
}: TProps) {
  const [isKeySpecsModalOpen, setIsKeySpecsModalOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState<null | number>(null);
  const param = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { role } = getLoggedInUser();

  const handleDeleteCompound = (id: number) => {
    setDeleteInProgress(id);
    dispatch(createProjectCompound({ id: id, is_active: false }))
      .then((res: any) => {
        if (res.payload.success) {
          setDeleteInProgress(null);
          // message.success('Succesfully deleted compound');
          if (!param.id) {
            navigate('/dashboard');
          } else {
            dispatch(
              getprojectDetail({
                id: Number(param.id),
              })
            );
          }
        }
      })
      .catch((err) => {
        setDeleteInProgress(null);
      });
  };

  return (
    <Spin spinning={deleteInProgress === id}>
      <div className="relative flex flex-col w-full gap-2 p-2 border rounded-lg sm:p-4 border-tertiary-highlight-200 hover:bg-tertiary-highlight-100 hover:scale-[1.01]">
        <Label
          label={'Compound Code:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={convertTextWithChemicalFormulas(compoundCode)}
        />
        {/* <Label
          label={'Alias Code:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={aliasCode}
        /> */}

        <Label
          label={'Quantity Requested:'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={quantityRequested}
        />
        <Label
          label={'Purity Requested (%):'}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          descriptionClasses={descriptionClasses}
          description={purityRequested}
        />
        {/* <Label
          label={'Key Specifications:'}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          description={
            <Button
              icon={<ReadOutlined />}
              onClick={() => setIsKeySpecsModalOpen(true)}
              shape="circle"
              size="small"
              disabled={!keySpecs}
            />
          }
        /> */}
        <Modal
          centered
          isOpen={isKeySpecsModalOpen}
          header={
            <h3 className="px-4 pt-2 text-sm font-bold font-open-sans">
              Key Specifications & Deliverables
            </h3>
          }
          footerComponent={
            <div className="flex justify-center flex-1 pb-2">
              <Button
                className="text-sm font-semibold text-white bg-primary hover:bg-transparent hover:!border-primary hover:!text-primary font-open-sans"
                onClick={() => setIsKeySpecsModalOpen(false)}
              >
                OK
              </Button>
            </div>
          }
        >
          <div className="px-4 py-0">
            <div dangerouslySetInnerHTML={{ __html: keySpecs }}></div>
          </div>
        </Modal>

        <div className="absolute flex items-center gap-2 top-1 right-1">
          <Tooltip
            title={'Key Specifications & Deliverables'}
            overlayClassName="custom-tooltip"
          >
            <Button
              icon={<ReadOutlined />}
              onClick={() => setIsKeySpecsModalOpen(true)}
              size="small"
              disabled={!keySpecs}
              className="bg-transparent border-none hover:opacity-75 hover:!text-black"
            />
          </Tooltip>
          {PERMISSIONS.canDeleteCompound(role) && compoundCodLength ? (
            compoundCodLength > 0 ? (
              <Tooltip title={'Delete'} overlayClassName="custom-tooltip">
                <img
                  src={deleteIcon}
                  alt="delete icon"
                  onClick={() => {
                    openNotification({
                      onApprove: () => handleDeleteCompound(id),
                      title: 'Deleting Compound Code',
                      subTitle:
                        'Are you sure you want to delete the Compound Code',
                      approveBtnClasses:
                        'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
                      cancelBtnClasses: 'px-10 py-4',
                      approveText: 'Delete',
                      cancelText: 'No',
                    });
                  }}
                  className="cursor-pointer hover:opacity-75"
                />
              </Tooltip>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </Spin>
  );
}
