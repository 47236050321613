export const EXECUTIVE_ACCESS = 'executive-access';
export const SECTION_HEAD = 'section-head';
export const PROPOSAL_LEAD = 'proposal-lead';
export const ADMIN = 'admin';
export const CHEMIST = 'chemist';
export const TECHNICAL_LEAD = 'technical-lead';

export const PERMISSIONS = {
  //if user can create a project
  canCreateProject: (role: string): boolean => {
    if (role === PROPOSAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can create a compound
  canCreateCompound: (role: string): boolean => {
    if (role === PROPOSAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can read a compound
  canReadCompound: (role: string): boolean => {
    if (
      role === PROPOSAL_LEAD ||
      role === EXECUTIVE_ACCESS ||
      role === SECTION_HEAD ||
      role === TECHNICAL_LEAD ||
      role === CHEMIST
    ) {
      return true;
    } else {
      return false;
    }
  },

  //if user can delete a compound
  canDeleteCompound: (role: string): boolean => {
    if (role === PROPOSAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can create a folder
  canCreateFolder: (role: string): boolean => {
    if (role === SECTION_HEAD || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can view a folders
  canReadFolders: (role: string): boolean => {
    if (
      role === SECTION_HEAD ||
      role === EXECUTIVE_ACCESS ||
      role === TECHNICAL_LEAD ||
      role === CHEMIST
    ) {
      return true;
    } else {
      return false;
    }
  },

  //if user can approved and reject the experiment
  canApproveRejectExperiment: (role: string): boolean => {
    if (role === TECHNICAL_LEAD || role === SECTION_HEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can close the experiment
  canCloseExperiment: (role: string): boolean => {
    if (role === TECHNICAL_LEAD || role === SECTION_HEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can submit the experiment for approval
  canSubmitExperiment: (role: string): boolean => {
    if (role === CHEMIST || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can edit the experiment
  canEditExperiment: (role: string): boolean => {
    if (role === CHEMIST || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can create the experiment
  canCreateExperiment: (role: string): boolean => {
    if (role === CHEMIST || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can add additional comment
  canAddAdditionalComment: (role: string): boolean => {
    if (role === SECTION_HEAD || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can see additional comment
  canReadAdditionalComment: (role: string): boolean => {
    if (role === CHEMIST || role === TECHNICAL_LEAD) {
      return true;
    } else {
      return false;
    }
  },

  //if user can view audit log
  canViewAuditLogs: (role: string): boolean => {
    if (
      role === CHEMIST ||
      role === SECTION_HEAD ||
      role === TECHNICAL_LEAD ||
      role === EXECUTIVE_ACCESS
    ) {
      return true;
    } else {
      return false;
    }
  },
};
