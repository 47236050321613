import { Avatar, Dropdown, Menu, MenuProps, message } from 'antd';
import person from 'assets/icons/person.svg';
import logout from 'assets/icons/logout.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { getIntitals, getLoggedInUser, getRoleName } from 'utilities/helpers';
import { logoutUser } from 'features/login/redux/slice';
import {
  ADMIN,
  CHEMIST,
  PROPOSAL_LEAD,
  SECTION_HEAD,
  EXECUTIVE_ACCESS,
  TECHNICAL_LEAD,
} from 'utilities/roleRights';

export default function ProfileDropdown({
  children,
}: {
  children: JSX.Element;
}) {
  const user = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const signOutClickHandler = () => {
    dispatch(logoutUser());
    // message.success('Successfully logged out').then(() => {
    //   navigate(0);
    // });
    message.success('Thanks! You have logged out successfully');
    navigate('/login');
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Menu.Item key="1">
          <div className="flex items-center gap-4">
            <Avatar className="text-white bg-primary !rounded-full" shape='circle'>
              {getIntitals(user?.user_details?.display_name)}
            </Avatar>
            <div>
              <div className="text-sm font-semibold font-open-sans text-tertiary-dark">
                {user?.user_details?.display_name}
              </div>
              <div className="text-xs text-tertiary-filter">
                {user?.user_details?.email}
              </div>
              <div className="text-xs text-tertiary-filter">
                {getRoleName(
                  user?.user_details?.role === CHEMIST &&
                    user?.user_details?.is_tl
                    ? TECHNICAL_LEAD
                    : user?.user_details?.role
                )}
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
    {
      key: '2',
      label: (
        <Menu.Item key="2">
          <div className="flex items-center gap-4">
            <img src={person} alt="profile" />
            <div>
              <div className="text-xs font-open-sans text-tertiary-dark">
                View Profile
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
    {
      key: '3',
      label: (
        <Menu.Item key="2" onClick={signOutClickHandler}>
          <div className="flex items-center gap-4">
            <img src={logout} alt="logout" />
            <div>
              <div className="text-xs font-semibold font-open-sans text-tertiary-dark">
                Logout
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
  ];

  return (
    <Dropdown placement="topLeft" menu={{ items }} arrow>
      {children}
    </Dropdown>
  );
}
