import { message, Tooltip } from 'antd';
import uploadIcon from 'assets/icons/upload.svg';
import { useRef, useState } from 'react';
import deleteIcon from 'assets/icons/delete.svg';

type TProps = {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  type?: 'vertical' | 'horizontal' | 'inline';
  limit?: number;
  allowedFileTypes?: string[];
  removable?: boolean;
  dragUpload?: boolean;
  disabled?: boolean;
};

export default function Upload({
  setFiles,
  files,
  type = 'vertical',
  limit,
  allowedFileTypes,
  removable = false,
  dragUpload = true,
  disabled = false,
}: TProps) {
  const [isDragging, setIsDragging] = useState(false);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!dragUpload) return;
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);

    const duplicateFiles = droppedFiles.filter((droppedFile) =>
      files.some((existingFile) => existingFile.name === droppedFile.name)
    );

    if (duplicateFiles.length > 0) {
      message.error({
        content: `The following files are already uploaded: ${duplicateFiles.map((file) => file.name).join(', ')}`,
        duration: 5,
      });
      return;
    }

    if (limit && files.length + droppedFiles.length > limit) {
      message.error({
        content: `You can only upload up to ${limit} documents. Please upload important documents only.`,
        duration: 5,
      });
      return;
    } else {
      setFiles([...files, ...droppedFiles]);
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles?.length) {
      const selectedFiles = Array.from(uploadedFiles);

      const duplicateFiles = selectedFiles.filter((selectedFile) =>
        files.some((existingFile) => existingFile.name === selectedFile.name)
      );

      if (duplicateFiles.length > 0) {
        message.error({
          content: `The following files are already uploaded: ${duplicateFiles.map((file) => file.name).join(', ')}`,
          duration: 5,
        });
        return;
      }

      if (limit && files.length + selectedFiles.length > limit) {
        message.error({
          content: `You can only upload up to ${limit} documents. Please upload important documents only.`,
          duration: 5,
        });
        return;
      } else {
        setFiles([...files, ...selectedFiles]);
      }
    }
  };

  const handleUploadClick = () => {
    const btn = fileInput.current;
    if (btn) {
      btn.click();
    }
  };

  return type === 'vertical' ? (
    <div
      className={`flex flex-col font-open-sans items-center justify-center  w-full px-6 py-3 border rounded-lg cursor-pointer ${isDragging ? 'border-primary-dark' : 'border-tertiary-highlight-200'}`}
      title="upload files"
      onClick={handleUploadClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <input
        ref={fileInput}
        type="file"
        multiple
        onChange={handleFileInputChange}
        accept={allowedFileTypes?.join(', ')}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      <img src={uploadIcon} alt="upload icon" />
      <label className="text-xs text-center font-open-sans text-tertiary-filter">
        Click to upload or drag and drop
      </label>
    </div>
  ) : type === 'horizontal' ? (
    <div
      className={`flex w-full overflow-hidden justify-center font-open-sans py-[0.7rem] border rounded-lg cursor-pointer ${isDragging ? 'border-primary-dark' : 'border-tertiary-highlight-200'} ${disabled && 'bg-tertiary-highlight-100'}`}
      title="upload files"
      onClick={handleUploadClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <input
        ref={fileInput}
        type="file"
        multiple
        onChange={handleFileInputChange}
        accept={allowedFileTypes?.join(', ')}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      <label className="flex items-center justify-center gap-1 cursor-pointer">
        {files.length ? (
          <div className="flex items-center justify-center">
            <Tooltip
              title={files.at(-1)?.name}
              overlayClassName="custom-tooltip"
            >
              <span className="overflow-hidden text-xs font-semibold text-center md:text-2xs lg:text-xs font-open-sans text-primary text-ellipsis whitespace-nowrap">
                {files.at(-1)?.name}
              </span>
            </Tooltip>
            {removable && (
              <img
                src={deleteIcon}
                className="ml-2 hover:opacity-60"
                onClick={(e) => {
                  e.stopPropagation();
                  setFiles([]);
                }}
              />
            )}
          </div>
        ) : (
          <>
            <span className="text-xs font-semibold text-center md:text-2xs lg:text-xs font-open-sans text-primary">
              Click to upload
            </span>{' '}
            {dragUpload && (
              <span className="text-xs font-normal text-center md:text-2xs lg:text-xs font-open-sans text-tertiary-filter">
                or drag and drop
              </span>
            )}
          </>
        )}
      </label>
    </div>
  ) : (
    <div
      className={`flex w-full justify-center font-open-sans py-[0.2rem] border rounded-lg cursor-pointer ${isDragging ? 'border-primary-dark' : 'border-tertiary-highlight-200'}`}
      title="upload files"
      onClick={handleUploadClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <input
        ref={fileInput}
        type="file"
        accept={allowedFileTypes?.join(', ')}
        multiple
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />
      <label className="flex items-center justify-center gap-1 cursor-pointer">
        <img src={uploadIcon} alt="upload icon" />
        <span className="text-xs font-semibold text-center md:text-2xs lg:text-xs font-open-sans text-primary">
          Click to upload
        </span>{' '}
        <span className="text-xs font-normal text-center md:text-2xs lg:text-xs font-open-sans text-tertiary-filter">
          or drag and drop
        </span>
      </label>
    </div>
  );
}
