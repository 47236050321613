import { Button, Input } from 'antd';
import dirLine from 'assets/icons/dir_line_angled.svg';
import Label from 'common/Label';
import deleteIcon from 'assets/icons/delete.svg';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAnalysis,
  addProcedure,
  getAnalysisList,
  getProdecedureList,
} from '../redux/slice';
import { useState } from 'react';
import { openNotification } from 'utilities/notification';
import { CheckOutlined } from '@ant-design/icons';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';

type TProps = {
  experiment: string;
  observation: string;
  id: string | number;
  stepNo: number;
  projectId: number | string;
  isCreate: boolean;
  isView: boolean;
  isEditing: boolean;
  type: string;
  isExternalChemist: boolean;
};

export default function Observation({
  experiment,
  observation,
  id,
  stepNo,
  projectId,
  isCreate,
  isView,
  isEditing,
  type,
  isExternalChemist
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const [idToRemove, setIdToRemove] = useState<number | null>(null);
  const [updatedExperiment, setUpdatedExperiment] = useState(
    convertTextWithChemicalFormulas(experiment)
  );
  const [updatedObservation, setUpdatedObservation] = useState(
    convertTextWithChemicalFormulas(observation)
  );

  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  const {
    analysisList,
    addAnalysisLoading,
    addProcedureLoading,
    experimentId,
    typeOfTestList,
  } = useSelector((state: RootState) => state.experiments);

  const handleProcedureChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    slug: 'experiment' | 'observation'
  ) => {
    if (slug === 'experiment') {
      setUpdatedExperiment(e.target.value);
    } else if (slug === 'observation') {
      setUpdatedObservation(e.target.value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      setUpdatedExperiment(convertTextWithChemicalFormulas(updatedExperiment));
      setUpdatedObservation(
        convertTextWithChemicalFormulas(updatedObservation)
      );
    }
  };

  const handleUpdateProcedure = (remark: string) => {
    const payload = {
      id,
      experiment_details:
        convertChemicalFormulasToSimpleText(updatedExperiment),
      procedure: convertChemicalFormulasToSimpleText(updatedObservation),
      is_active: true,
      type,
      change_remarks: remark,
      section: 'procedure',
      is_edit:  isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addProcedure(payload)).then((res: any) => {
      setIsRemarksModalOpen(false)
      if (res?.payload?.success) {
        setIdToRemove(null);
        dispatch(
          getProdecedureList({
            project_id: projectId,
            experiment_id: experimentId,
          })
        );
      } else {
        setIdToRemove(null);
      }
    });
  };

  const handleSubmit = (value: string) => {
    if (isRemarksModalOpen) {
      handleUpdateProcedure(value);
    } else {
      setIsRemarksModalOpen(true);
    }
  };

  const handleDeleteProcedureWithAnalysis = (
    payload: { id: string | number; is_active: boolean },
    analysisId: string
  ) => {
    dispatch(addProcedure(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToRemove(null);
        const formdata = new FormData();

        formdata.append('id', analysisId);
        formdata.append('is_active', 'false');
        dispatch(addAnalysis(formdata)).then((res: any) => {
          if (res?.payload?.success) {
            dispatch(
              getAnalysisList({
                project_id: projectId,
                experiment_id: experimentId,
              })
            );
            dispatch(
              getProdecedureList({
                project_id: projectId,
                experiment_id: experimentId,
              })
            );
          }
        });
      } else {
        setIdToRemove(null);
      }
    });
  };

  const handleDeleteProcedure = () => {
    const payload = {
      id,
      is_active: false,
      is_edit:  isView && isExternalChemist ? false : isView ? true : false,
    };
    const foundAnalysisObj = analysisList.find(
      (obj) => obj.serial_number === stepNo
    );

    if (foundAnalysisObj) {
      openNotification({
        onCancel: () => setIdToRemove(null),
        onApprove: () =>
          handleDeleteProcedureWithAnalysis(payload, foundAnalysisObj.id),
        okBtnLoading: addAnalysisLoading,
        title: 'Conflicting Procedure',
        subTitle: (
          <span className="text-sm font-open-sans">
            This procedure has serial number linked with one of the analysis of
            type
            <span className="ml-2 text-sm font-semibold font-open-sans">
              {
                typeOfTestList.find(
                  (type) => type.id === foundAnalysisObj.type_of_test_id
                )?.type_of_test
              }
            </span>
          </span>
        ),
        approveBtnClasses:
          'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
        cancelBtnClasses: 'px-10 py-4',
        approveText: 'Delete',
        cancelText: 'No',
      });
    } else {
      dispatch(addProcedure(payload)).then((res: any) => {
        if (res?.payload?.success) {
          setIdToRemove(null);
          dispatch(
            getProdecedureList({
              project_id: projectId,
              experiment_id: experimentId,
            })
          );
        } else {
          setIdToRemove(null);
        }
      });
    }
  };

  return (
    <div className="flex items-start w-full gap-1 mt-2 text-sm md:flex-row font-open-sans text-tertiary-filter md:gap-4">
      <p className="font-open-sans text-sm text-tertiary-filter w-[5%] text-center">
        {stepNo}
      </p>
      <div className="flex flex-1">
        <div className="flex flex-col items-start justify-start md:items-end">
          <label className="z-50 mr-2 text-xs font-semibold border-red-500 text-tertiary-dark lg:text-sm font-open-sans">
            Experiment:
          </label>
        </div>
        <div className={`flex flex-col w-full gap-3`}>
          {isView && isEditing ? (
            <Input.TextArea
              autoSize
              defaultValue={experiment}
              value={updatedExperiment}
              className="w-full px-3 py-2 text-xs resize-none lg:text-sm font-open-sans focus-within:border-primary"
              onChange={(e) => handleProcedureChange(e, 'experiment')}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <p className="text-xs text-tertiary-dark lg:text-sm font-open-sans">
              {convertTextWithChemicalFormulas(experiment)}
            </p>
          )}
          <Label
            label={
              <div className="relative">
                <div className="hidden -left-14 bottom-[20%] absolute object-fill w-12 h-auto lg:h-12 md:inline z-20">
                  <img
                    src={dirLine}
                    alt="direction line to observation"
                    className="h-full"
                  />
                </div>
                <label>Observation:</label>
              </div>
            }
            containerClasses="flex flex-col md:flex-row items-start gap-1 md:gap-2 mt-4"
            labelClasses="font-semibold text-tertiary-dark text-xs lg:text-sm font-open-sans"
            descriptionClasses="text-tertiary-dark w-full text-xs lg:text-sm font-open-sans"
            description={
              isView && isEditing ? (
                <Input.TextArea
                  autoSize
                  defaultValue={observation}
                  value={updatedObservation}
                  className="w-full px-3 py-2 text-xs resize-none lg:text-sm font-open-sans focus-within:border-primary"
                  onChange={(e) => handleProcedureChange(e, 'observation')}
                  onKeyDown={handleKeyDown}
                />
              ) : (
                convertTextWithChemicalFormulas(observation)
              )
            }
          />
        </div>
      </div>
      {isView && isEditing && (
        <div className="w-[10%] flex justify-center gap-2 my-auto">
          <Button
            icon={<CheckOutlined />}
            onClick={() => {
              handleSubmit('');
              setIdToRemove(id as number);
            }}
            className="text-green-500 hover:opacity-75 !border-none"
            disabled={
              updatedExperiment === experiment &&
              updatedObservation === observation
            }
            loading={idToRemove && idToRemove === id ? true : false}
          />
          <Button
            icon={<img src={deleteIcon} />}
            onClick={() => {
              handleDeleteProcedure();
              setIdToRemove(id as number);
            }}
            className="hover:opacity-75 !border-none"
            loading={idToRemove && idToRemove === id ? true : false}
          />
          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={addProcedureLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      )}
      {isCreate && (
        <div className="w-[10%] text-center my-auto">
          <Button
            icon={<img src={deleteIcon} />}
            onClick={() => {
              handleDeleteProcedure();
              setIdToRemove(id as number);
            }}
            loading={idToRemove && idToRemove === id ? true : false}
          />
        </div>
      )}
    </div>
  );
}
