import { Button, Input, Table } from 'antd';
import FormInput from 'common/FormInput';
import deleteIcon from 'assets/icons/delete.svg';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import RemarksModal from './RemarksModal';
import { useState } from 'react';
// import { useState } from 'react';
// import {
//   convertTextWithChemicalFormulas,
//   convertChemicalFormulasToSimpleText,
// } from 'utilities/helpers';

type TProps = {
  onAdd: (type: string, remark: string) => void;
  type: string;
  isCreate: boolean;
};

export default function ProcedureTable({ onAdd, type, isCreate }: TProps) {
  const { sm, xs } = useResponsiveness();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  // const [updatedExperiment, setUpdatedExperiment] = useState('');
  // const [updatedObservation, setUpdatedObservation] = useState('');

  const { addProcedureLoading } = useSelector(
    (state: RootState) => state.experiments
  );

  // const handleProcedureChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   slug: `experiment_${string}` | `observation_${string}`
  // ) => {
  //   if (slug === 'experiment_${type}') {
  //     setUpdatedExperiment(e.target.value);
  //   } else if (slug === 'observation_${type}') {
  //     setUpdatedObservation(e.target.value);
  //   }
  // };
  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === ' ') {
  //     // Check for spacebar key
  //     setUpdatedExperiment(convertTextWithChemicalFormulas(updatedExperiment));
  //     setUpdatedObservation(
  //       convertTextWithChemicalFormulas(updatedObservation)
  //     );
  //   }
  // };

  const handleSubmit = (type: string, remark: string) => {
    if (isCreate) {
      onAdd(type, remark);
    } else {
      if (isRemarksModalOpen) {
        onAdd(type, remark);
        setIsRemarksModalOpen(false);
      } else {
        setIsRemarksModalOpen(true);
      }
    }
  };

  const columns = [
    {
      title: (
        <span className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Sno.
        </span>
      ),
      dataIndex: 's_no',
      key: 's_no',
      width: '5%',
    },
    {
      title: (
        <span className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Experiment
        </span>
      ),
      dataIndex: 'experiment',
      key: 'experiment',
      width: '%',
      render: (text: string, record: any, index: number) =>
        index === 0 ? (
          <FormInput
            hideColon
            inputClasses="p-input rounded-none font-open-sans shadow-none border-none focus-within:border-none hover:border-none"
            defaultStyle={false}
            name={`experiment_${type}`}
            placeholder="Enter experiment details"
            // className="border-none rounded-none shadow-none p-input font-open-sans focus-within:border-none hover:border-none"
            // value={updatedExperiment}
            // onChange={(e) => handleProcedureChange(e, `experiment_${type}`)}
            // onKeyDown={handleKeyDown}
          />
        ) : (
          text
        ),
    },
    {
      title: (
        <span className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Observation
        </span>
      ),
      dataIndex: 'observation',
      key: 'observation',
      render: (text: string, record: any, index: number) =>
        index === 0 ? (
          <FormInput
            inputClasses="p-input font-open-sans shadow-none border-none focus-within:border-none hover:border-none"
            hideColon
            defaultStyle={false}
            name={`observation_${type}`}
            placeholder="Enter observation"
          />
        ) : (
          text
        ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (text: string, record: any, index: number) =>
        index === 0 ? (
          <>
            <Button
              type="primary"
              className="bg-transparent border text-primary border-primary hover:!text-white hover:!bg-primary"
              onClick={() => handleSubmit(type, '')}
              loading={addProcedureLoading}
            >
              Add
            </Button>

            {/*change remarks modal */}
            <RemarksModal
              isOpen={isRemarksModalOpen}
              onApprove={(value) => handleSubmit(type, value)}
              loading={addProcedureLoading}
              onClose={() => setIsRemarksModalOpen(false)}
            />
          </>
        ) : (
          <Button icon={<img src={deleteIcon} />} />
        ),
    },
  ];

  return (
    <Table
      columns={columns}
      components={{
        body: {
          row: (props: any) => {
            return <tr className="hover:opacity-7">{props.children}</tr>;
          },
          cell: (props: any) => {
            return <td {...props} className="!py-0" />;
          },
        },
        header: {
          cell: (props: any) => {
            return (
              <th
                {...props}
                className="!border-none !py-2 !rounded-none !bg-tertiary-highlight-100"
              >
                {props.children}
              </th>
            );
          },
          row: (props: any) => {
            return <tr>{props.children}</tr>;
          },
        },
      }}
      dataSource={[{}]}
      pagination={false}
    />
  );
}
