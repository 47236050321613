import { Avatar, Flex, Layout, Popover } from 'antd';
import logo from 'assets/icons/logo_new.svg';
import logo_full from 'assets/icons/logo_full_new.svg';
import profile from 'assets/icons/profile.svg';
import { NAVLINKS, type TNavLinks } from 'common/Layout/components/navlinks';
import { NavLink, useLocation } from 'react-router-dom';
import ProfileDropdown from 'common/Layout/components/ProfileDropdown';
import { getIntitals, getLoggedInUser } from 'utilities/helpers';
import arrow_right_angled from 'assets/icons/arrow_right_angled.svg';
import { useState } from 'react';
import ProjectSearch from './components/ProjectSearch';
import FolderSearch from './components/FolderSearch';
import LinkDropdown from './components/LinkDropdown';

const SideNavBar: React.FC = () => {
  const user = getLoggedInUser();

  const NavItem = ({
    link,
    isActive,
    avatar,
    hideIndicator = false,
  }: {
    link: TNavLinks;
    isActive: boolean;
    avatar?: JSX.Element;
    hideIndicator?: boolean;
  }) => {
    const [isContentOpen, setIsContentOpen] = useState<boolean>(false);
    //dropdown link content
    const renderLinkContent = (linkId: number | undefined) => {
      const id = NAVLINKS.find((link) => link.id === linkId)?.id;

      switch (id) {
        case 2:
          return (
            <ProjectSearch
              setIsOpen={setIsContentOpen}
              isOpen={isContentOpen}
            />
          );
        case 3:
          return (
            <FolderSearch setIsOpen={setIsContentOpen} isOpen={isContentOpen} />
          );
        default:
          return null;
      }
    };
    return (
      <LinkDropdown
        isOpen={isContentOpen && Boolean(link.isDisabled)}
        setIsOpen={setIsContentOpen}
        content={renderLinkContent(link.id)}
      >
        <Flex
          vertical
          key={link.id}
          align="center"
          justify="center"
          className={`mb-1 cursor-pointer relative hover:opacity-75 py-3`}
        >
          {isActive && !hideIndicator && (
            <div className="absolute w-2 h-full rounded-full -right-1 bg-primary-dark"></div>
          )}
          {avatar ? (
            avatar
          ) : (
            <img
              key={link.id}
              src={isActive ? link.activeIcon : link.icon}
              alt={link.label}
              className="mb-1"
            />
          )}
          <h6
            className={`font-semibold lg:text-sm font-open-sans text-center hidden text-xs md:inline-block ${
              isActive
                ? 'text-primary hover:text-primary-filter'
                : 'text-tertiary hover:text-primary-filter'
            }`}
          >
            {link.label}
          </h6>
        </Flex>
      </LinkDropdown>
    );
  };

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    isDisabled: boolean | undefined
  ) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };

  const renderedLinks = NAVLINKS?.filter((link) =>
    link.access?.includes(user.role)
  ).map((link: TNavLinks): JSX.Element => {
    return (
      <NavLink
        key={link.id}
        onClick={(e) => {
          handleClick(e, link.isDisabled);
        }}
        to={link.path}
        className="!relative"
      >
        {({ isActive }) => (
          <NavItem
            link={link}
            isActive={
              isActive ||
              (window.location.pathname === '/' && link.path === 'dashboard') ||
              (window.location.pathname.includes("project") && link.path === 'dashboard')
            }
          />
        )}
      </NavLink>
    );
  });

  return (
    <Layout.Sider
      width="10%"
      className="relative hidden border-r sm:block bg-secondary border-r-primary-dark"
    >
      <Flex
        justify="space-between"
        align="center"
        vertical
        className="my-8 h-700:my-6 gap-11 h-700:!gap-2"
      >
        {/* <div className="flex flex-col items-center justify-center gap-2 mx-4">
          <img src={logo} alt="logo" className="w-4/5 md:w-3/5 lg:w-1/2" />
          <img
            src={logo_full}
            alt="logo_full"
            className="w-4/5 md:w-3/5 lg:w-1/2"
          />
        </div> */}
        <img src={logo_full} alt="logo_full" className="w-3/5" />
        <Flex justify="center" vertical className="w-full gap-2 h-700:!gap-0">
          {renderedLinks}
        </Flex>
      </Flex>
      <ProfileDropdown>
        <div className="absolute w-4/5 -translate-x-1/2 rounded-lg left-1/2 hover:bg-secondary-dark bottom-4 h-700:bottom-2">
          <img src={arrow_right_angled} className="absolute right-0" />
          <NavItem
            link={{
              label: user?.user_details?.display_name
                ? user?.user_details?.display_name
                : '',
              path: '',
              icon: profile,
              activeIcon: profile,
            }}
            hideIndicator
            isActive
            avatar={
              <Avatar
                className="mb-2 text-white bg-primary !rounded-full"
                shape="circle"
              >
                {getIntitals(user?.user_details?.display_name)}
              </Avatar>
            }
          />
        </div>
      </ProfileDropdown>
    </Layout.Sider>
  );
};

export default SideNavBar;
