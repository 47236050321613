import { useCallback, useEffect } from 'react';
import {
  Flex,
  Spin,
  Button,
  Tooltip,
  Badge,
  Dropdown,
  MenuProps,
  Checkbox,
} from 'antd';
// import FormInput from 'common/FormInput';
// import search from 'assets/icons/search.svg';
import Table from 'common/Table';
import { useState } from 'react';
import { getLoggedInUser } from 'utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getprojectList } from '../redux/slice';
import SearchBar from 'common/Content/searchBar';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import './ListViewStyles.css';
import moment from 'moment';
import { debounce } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { PERMISSIONS, PROPOSAL_LEAD } from 'utilities/roleRights';
import ColumnDropdownFilter from 'common/ColumnDropdownFilter';

interface payload {
  page: number;
  perPage: number;
  search: string;
  from_date: string;
  to_date: string;
}

const PER_PAGE_ITEM = 15;

// const renderColumn = (text: string) => (
//   <p className="text-xs font-open-sans text-tertiary-filter ">{text}</p>
// );

export default function ListView() {
  const { role } = getLoggedInUser();

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Project Code
        </label>
      ),
      dataIndex: 'project',
      key: 'project',
      width: '7%',
      sorter: (a: any, b: any) => a.project?.localeCompare(b.project),
      render: (text: string, record: any, index: number) => {
        return (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            {record.is_new && (
              <Badge.Ribbon
                placement="start"
                text="New"
                // className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center ${index === 0 ? '-top-4' : '-top-5'} !-left-4 text-2xs bg-primary`}
                className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center ${index === 0 ? '-top-4' : '-top-5'} !-left-4 text-2xs bg-primary !pl-3`}
              ></Badge.Ribbon>
            )}
            <p className="relative textStyle !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {/* {record.is_new && (
                <div className="absolute bottom-0 w-1 h-12 -top-4 -left-4 bg-primary"></div>
                )} */}
              {text}
            </p>
          </Tooltip>
        );
      },
      //renderColumn,
    },

    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Industry Type
        </label>
      ),
      dataIndex: 'industryType',
      key: 'industryType',
      width: '10%',
      sorter: (a: any, b: any) => a.industryType?.localeCompare(b.industryType),
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          {' '}
          <p className="textStyle">{text}</p>{' '}
        </Tooltip>
      ),
      //renderColumn,
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Project Details
        </label>
      ),
      dataIndex: 'projectDetails',
      key: 'projectDetails',
      width: '15%',
      ellipsis: true,
      sorter: (a: any, b: any) => a.project?.localeCompare(b.project),
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="textStyle">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Section Head
        </label>
      ),
      dataIndex: 'sectionHead',
      key: 'sectionHead',
      width: '11%',
      sorter: (a: any, b: any) => a.sectionHead?.localeCompare(b.sectionHead),
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="textStyle">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Assigned Date
        </label>
      ),
      dataIndex: 'assignedDate',
      key: 'assignedDate',
      width: '10%',
      sorter: (a: any, b: any) =>
        moment(a.assignedDate).diff(moment(b.assignedDate)),
      render: (text: string) => (
        <Tooltip
          overlayClassName="custom-tooltip"
          title={moment(text).format('DD MMM YYYY')}
        >
          {' '}
          <p className="textStyle">
            {text === null ? 'NA' : moment(text).format('DD MMM YYYY')}
          </p>
        </Tooltip>
      ),
      filterDropdown: () => (
        <ColumnDropdownFilter
          type="date"
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Status
        </label>
      ),
      hidden: true,
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          {' '}
          <p className="textStyle">{text}</p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_: any, record: any) => (
        <Tooltip
          title={'View'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
        >
          <Link
            to={
              role === PROPOSAL_LEAD
                ? `/projects/${record.key}`
                : `/folders/${record.key}`
            }
            style={{ color: '#0098da' }}
          >
            <EyeOutlined className="text-xs text-tertiary-filter hover:text-primary-dark" />
          </Link>
        </Tooltip>
      ),
      //renderColumn,
    },
  ];
  const dispatch: AppDispatch = useDispatch();
  const user = getLoggedInUser();
  const navigate = useNavigate();
  // const onSearch = ({ search }: { search: string }) => {
  //   console.log('SEARCH>>>', search);
  // };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchProject, setSearchProject] = useState<string>('');

  const { getprojectListLoading, projectListData, pagination } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    const payload: payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchProject,
      from_date: fromDate,
      to_date: toDate,
    };
    dispatch(getprojectList(payload));
  }, [searchProject, currentPage, fromDate, toDate]);

  const projectList = projectListData?.map((item: any) => {
    return {
      key: item?.id,
      is_new: item.is_new,
      project: item?.project_code,
      proposalCode: item?.proposal_code,
      sectionHead: item?.section_heads
        .map((head: any) => head.section_head_user.display_name)
        .join(', '),
      industryType: item?.industry_type?.industry_type,
      projectDetails: item?.project_description,
      assignedDate:
        item?.section_heads?.length > 0
          ? item?.section_heads[0]?.assigned_on
          : null,
      status: item?.status,
      action: 'View',
    };
  });

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchProject(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  return (
    <Flex vertical className="h-full shadow-equal-sides rounded-xl">
      <Spin spinning={getprojectListLoading}>
        <Flex
          justify="space-between"
          align="center"
          className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
        >
          <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
            Welcome back, {user?.user_details?.first_name}
          </h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {/* <Form layout="vertical" className="w-1/5" onFinish={onSearch}>
              <FormInput
                name="search"
                placeholder="Search"
                prefix={<img src={search} alt="search" className="mr-2 " />}
                inputClasses="w-full p-2"
              />
            </Form> */}
            <SearchBar
              justify="start"
              align="middle"
              gutter={32}
              inputProps={{
                placeholder: 'Search by project code',
              }}
              filters={false}
              onChange={(e: any) => handleSearchChange(e.target.value)}
              //className={styles.searchBar_styles}
            />
            {PERMISSIONS.canCreateProject(user.role) && (
              <Button
                size="middle"
                icon={<PlusOutlined />}
                // className="buttonStyles"
                className="text-primary bg-white font-open-sans hover:bg-primary-dark hover:!text-white font-semibold border border-primary-dark"
                onClick={() => navigate('/projects')}
              >
                Create Project
              </Button>
            )}
          </div>
        </Flex>
        <Flex className="relative h-full m-2 overflow-auto sticky-footer">
          <Table
            totalPages={pagination?.total_pages}
            pagination={true}
            pageSize={PER_PAGE_ITEM}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            columns={columns}
            dataSource={projectList}
          />
        </Flex>
      </Spin>
    </Flex>
  );
}
