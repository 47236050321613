import { Col, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientDetailsList } from '../redux/slice';
import { debounce } from 'lodash';
import { generateUniqueProjectCodeKey } from 'utilities/helpers';
import './industrialDetails.css';
import Label from 'common/Label';

interface Option {
  label: string;
  value: string;
  classNames: string;
  Children?: Option[];
}

interface IndustrialDetailsProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  selectedChildOption: string;
  setSelectedChildOption: (value: string) => void;
  setGenProjectCode: (value: string) => void;
  industryTypeError: null | string;
}

const options: Option[] = [
  {
    label: 'Pharma',
    value: '1',
    classNames: 'border border-tertiary-highlight-200 p-1 rounded-lg ',
    Children: [
      {
        label: 'GMP',
        value: '1-1',
        classNames: ' p-2 rounded-lg',
      },
      {
        label: 'Development',
        value: '1-2',
        classNames: ' p-2 rounded-lg',
      },
    ],
  },
  {
    label: 'Specialty',
    value: '2',
    classNames: 'border border-tertiary-highlight-200 p-1 rounded-lg',
  },
];

// type TProps = {
//   options: Options[];
//   activeRadioId?: string | number;
// };

export default function IndustrialDetails({
  selectedOption,
  setSelectedOption,
  selectedChildOption,
  setSelectedChildOption,
  setGenProjectCode,
  industryTypeError,
}: IndustrialDetailsProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const { clientDetailsList } = useSelector(
    (state: RootState) => state.project2
  );

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setSelectedChildOption(''); // Reset child option when changing main option
  };

  const handleChildOptionChange = (value: string) => {
    setSelectedChildOption(value);
  };

  useEffect(() => {
    // Generate a new key only if a valid option is selected
    const handleProjectCodeGeneration = () => {
      if (
        (selectedOption && selectedOption === '1' && selectedChildOption) ||
        selectedOption === '2'
      ) {
        const newKey = generateUniqueProjectCodeKey(
          selectedOption,
          selectedChildOption
          // dispatch
        );
        setGenProjectCode(newKey);
      } else {
        setGenProjectCode(''); // Keep as empty if no valid selection
      }
    };

    handleProjectCodeGeneration();
  }, [selectedOption, selectedChildOption]);

  const renderedClientOptions = clientDetailsList?.map((client) => {
    return { label: client.client_name, value: client.id };
  });

  useEffect(() => {
    const payload = {
      search: searchTerm,
    };

    dispatch(getClientDetailsList(payload));
  }, [searchTerm]);

  const handleClientSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    []
  );

  const onClientSearch = (value: string) => {
    handleClientSearch(value);
  };

  return (
    <Card>
      <>
        {/* <FormInput
          name="industry_type_id"
          label="Industry type"
          defaultStyle={true}
          type="radio"
          selectedRadioId={activeRadioId}
          options={options}
          inputClasses=" "
        /> */}
        <div className="flex flex-col gap-3 my-3">
          <Label label={'Industry Type'} required />
          <div className="options-container">
            {options.map((option) => (
              <div
                key={option.value}
                className={`${option.classNames} ${selectedOption === option.value ? 'active' : ''}`}
              >
                <label className="flex custom-items-center">
                  <input
                    type="radio"
                    name="mainOption"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={() => handleOptionChange(option.value)}
                    className=" accent-primary"
                  />
                  <span className="ml-1">{option.label}</span>
                </label>

                {selectedOption === option.value && option.Children && (
                  <div
                    className={`child-options border border-tertiary-highlight-200 rounded-lg ${selectedOption === option.value ? 'active' : ''}`}
                  >
                    {option.Children.map((child) => (
                      <div key={child.value} className="">
                        <label className="flex custom-items-center">
                          <input
                            type="radio"
                            name="subOption"
                            value={child.value}
                            checked={selectedChildOption === child.value}
                            onChange={() =>
                              handleChildOptionChange(child.value)
                            }
                            className=" accent-secondary-black"
                          />
                          <span className="ml-1 font-normal font-open-sans">
                            {child.label}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <span className="text-xs text-red-500 font-open-sans">
            {industryTypeError ? industryTypeError : null}
          </span>
        </div>

        <Row gutter={20}>
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="project_code"
              label="Project Code"
              defaultStyle={true}
              disabled
              // placeholder={
              //   !selectedOption ||
              //   (selectedOption === '1' && !selectedChildOption)
              //     ? 'Project code'
              //     : 'Generated automatically on project creation'
              // }
              // inputClasses={`p-2 font-open-sans text-secondary-black shadow-none border focus-within:border-primary-dark ${!selectedOption || (selectedOption === "1" && !selectedChildOption)? "font-normal" : " font-semibold placeholder:text-secondary-black"} hover:border-tertiary-highlight-200`}
              inputClasses="p-input font-open-sans text-secondary-black shadow-none border focus-within:border-primary-dark !text-sm"
              placeholder="Generated automatically on project creation"
            />
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="proposal_code"
              label="Proposal Code"
              inputMaxLength={6}
              defaultStyle={true}
              placeholder="Enter proposal code"
              required
              validateMsg="Proposal code is required"
            />
          </Col>
          <Col span={24} sm={{ span: 8 }} className="">
            <FormInput
              name="client_id"
              label="Client Name"
              type="select"
              onSearch={onClientSearch}
              defaultStyle={true}
              placeholder="Select client"
              options={renderedClientOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormInput
              name="project_description"
              label="Project Details"
              rows={5}
              type="textarea"
              placeholder="Enter project details"
              defaultStyle={true}
            />
          </Col>
        </Row>
      </>
    </Card>
  );
}
