import { Button, Flex, Form } from 'antd';
import Modal from './Modal';
import { CloseOutlined } from '@ant-design/icons';
import FormInput from './FormInput';

type TProps = {
  onApprove: (value: string) => void;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
};

export default function RemarksModal({
  onApprove,
  isOpen,
  loading,
  onClose,
}: TProps) {
  const [form] = Form.useForm();
  return (
    <Form
      id="context_modal_form"
      onFinish={(values: any) => onApprove(values)}
      form={form}
      layout="vertical"
    >
      <Modal
        isOpen={isOpen}
        btnLoading={loading}
        centered
        header={
          <div className="flex items-center justify-between px-3 py-2">
            <h3 className="text-base font-bold text-tertiary-dark">
              Change Remarks
            </h3>
            <Button
              className="bg-transparent border-none"
              icon={<CloseOutlined />}
              shape="circle"
              onClick={() => {
                onClose();
                form.resetFields();
              }}
            />
          </div>
        }
        footerComponent={
          <div className="flex justify-center pb-2">
            <Button
              type="primary"
              className="px-10 text-white bg-green-600 font-open-sans"
              onClick={() => {
                const remark = form.getFieldValue('change_remarks');
                onApprove(remark);
              }}
              loading={loading}
            >
              OK
            </Button>
          </div>
        }
      >
        <Flex vertical gap={'0.5rem'}>
          <span className="px-3 text-sm text-tertiary-filter">
            Enter remarks for changes in this section
          </span>
          <div className="pr-3">
            <FormInput
              type="textarea"
              name="change_remarks"
              label={<span className="hidden">label</span>}
              placeholder={'Enter change remarks'}
              defaultStyle
            />
          </div>
        </Flex>
      </Modal>
    </Form>
  );
}
