import pc from 'assets/icons/pc.svg';

export default function ActivityLog() {
  return (
    <div className="flex items-start gap-4 px-2 pb-4 border-b sm:px-4">
      <img src={pc} />
      <div className="flex flex-col gap-1 font-open-sans">
        <label className="text-sm font-semibold font-open-sans">User1</label>
        <p className="text-sm font-open-sans text-tertiary-filter">
          Performed email server maintenance; cleaned logs and optimized
          performance. Scheduled next maintenance for September.
        </p>
      </div>
    </div>
  );
}
