import {
  EditOutlined,
  FileSearchOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Flex, Space, Tooltip } from 'antd';
import {
  getFieldformattedText,
  getFieldNameText,
  getLoggedInUser,
  STATUS,
} from 'utilities/helpers';
import { PERMISSIONS, TECHNICAL_LEAD } from 'utilities/roleRights';
import Modal from './Modal';
import { useEffect, useState } from 'react';
import Table from './Table';
import ModalCtx from './ModalCtx';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch } from 'react-redux';
import {
  getExperimentLogs,
  setIsExternalChemist,
} from 'features/sectionHeadFlow/experiments/redux/slice';
import moment from 'moment';
import RemarksModal from './RemarksModal';

const columns = [
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Field
      </label>
    ),
    dataIndex: 'field',
    key: 'field',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed from
      </label>
    ),
    dataIndex: 'changed_from',
    key: 'changed_from',
    width: '20%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed To
      </label>
    ),
    dataIndex: 'changed_to',
    key: 'changed_to',
    width: '17%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed By
      </label>
    ),
    dataIndex: 'changed_by',
    key: 'changed_by',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Changed On
      </label>
    ),
    dataIndex: 'changed_on',
    key: 'changed_on',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
  {
    title: (
      <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
        Remarks
      </label>
    ),
    dataIndex: 'remarks',
    key: 'remarks',
    width: '16%',
    render: (text: string) => (
      <Tooltip title={text} overlayClassName="custom-tooltip">
        <p className="text-2xs font-open-sans text-tertiary-filter text-transform-capitalize">
          {text}
        </p>
      </Tooltip>
    ),
  },
];

type TLogs = {
  field: string;
  changed_from: string;
  changed_to: string;
  changed_by: string;
  changed_on: string;
  remarks: string;
};

type TProps = {
  isView: boolean;
  experimentStatus: string | undefined;
  isEditing: boolean;
  onSave: () => void;
  title: string;
  customHeader?: JSX.Element | null;
  saveLoading?: boolean;
  experimentId: string | null;
};

export default function SectionHeader({
  isView,
  experimentStatus,
  onSave,
  isEditing,
  customHeader,
  title,
  saveLoading,
  experimentId,
}: TProps) {
  //state and function for opening and closing the remarks modal after saving
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const handleOpenRemarksModal = () => setIsRemarksModalOpen(true);
  const handleCloseRemarksModal = () => setIsRemarksModalOpen(false);
  const { experimentDetails, experimentLogs, experimentLogsLoading } =
    useSelector((state: RootState) => state.experiments);
  const [hasEditAccess, setHasEditAccess] = useState(false);
  const { role, user_details } = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();

  //state and function for opening and closing the Audit Logs modal
  const [isAuditLogsOpen, setIsAuditLogsOpen] = useState(false);
  const handleOpenAuditLogs = () => setIsAuditLogsOpen(true);
  const handleCloseAuditLogs = () => setIsAuditLogsOpen(false);

  //handle click on save btn
  const handleOnSave = () => {
    onSave();
    if (isEditing) {
      handleOpenRemarksModal();
    }
  };

  //transform audi logs
  const transformedAuditLogs: TLogs[] = experimentLogs.length
    ? experimentLogs
        .filter((log: any) => {
          const fieldsToRemove: string[] = ['folder_route_scheme_id'];
          return !fieldsToRemove.includes(log.field);
        })
        .map((logs: any) => {
          return {
            field: getFieldNameText(logs.field),
            changed_from: getFieldformattedText(logs.changed_from),
            changed_to: getFieldformattedText(logs.changed_to),
            changed_by: logs.user?.first_name,
            remarks: getFieldformattedText(logs.change_remarks),
            changed_on: moment(logs.changed_on).format('DD/MM/YYYY hh:mm a'),
          };
        })
    : [];

  //getting audio logs
  useEffect(() => {
    if (isAuditLogsOpen) {
      const payload = {
        experiment_id: experimentId,
        section: title.toLowerCase().split(' ').join('_').trim(),
      };
      dispatch(getExperimentLogs(payload));
    }
  }, [experimentId, isAuditLogsOpen]);

  //checking edit access
  useEffect(() => {
    if (
      experimentDetails?.authors?.length &&
      experimentDetails?.folder?.project_chemists?.length
    ) {
      const foundChemist = experimentDetails?.authors.some(
        (chemist: any) => chemist.id === user_details?.id
      );

      if (
        foundChemist &&
        (experimentStatus === STATUS.pending ||
          experimentStatus === STATUS.draft ||
          experimentStatus === STATUS.rejected)
      ) {
        setHasEditAccess(true);
        dispatch(setIsExternalChemist(false));
        return;
      }

      //if TL is author
      const isTLAuthor = experimentDetails?.authors.some((chemist: any) => {
        const foundProjectChemist =
          experimentDetails.folder?.project_chemists.find(
            (user: any) => user.chemist_id === chemist?.id
          );

        return foundProjectChemist && foundProjectChemist.is_tl;
      });

      const foundProjectChemist =
        experimentDetails.folder?.project_chemists.find(
          (user: any) => user.chemist_id === user_details?.id
        );

      if (isTLAuthor && !foundProjectChemist?.is_tl) {
        setHasEditAccess(false);
        return;
      }

      if (foundProjectChemist && isView) {
        if (
          foundProjectChemist.is_tl &&
          !foundProjectChemist.globalTl &&
          experimentStatus === STATUS.pending
        ) {
          setHasEditAccess(true);
          dispatch(setIsExternalChemist(false));
          return;
        } else if (
          !foundProjectChemist.is_tl &&
          (experimentStatus === STATUS.pending ||
            experimentStatus === STATUS.rejected)
        ) {
          setHasEditAccess(true);
          dispatch(setIsExternalChemist(true));
        } else {
          setHasEditAccess(false);
        }
      } else {
        setHasEditAccess(false);
      }
    } else {
      setHasEditAccess(false);
    }
  }, [hasEditAccess, experimentDetails]);

  //handle click on ok btn in remarks modal
  const handleSavingRemarks = (values: any, slug: string) => {
    console.log('saving remarks::', {
      change_remarks: values.edit_remarks,
      slug,
    });
  };

  //custom header
  if (customHeader) {
    return customHeader;
  } else {
    // if is view and is creator of this experiment
    if (hasEditAccess) {
      return (
        <Flex justify="space-between" align="center">
          <label className="text-sm sm:text-base">{title}</label>
          <Space>
            {PERMISSIONS.canViewAuditLogs(role) && (
              <>
                <Button
                  className="bg-transparent hidden md:block hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                  icon={<FileSearchOutlined />}
                  onClick={handleOpenAuditLogs}
                >
                  Audit Logs
                </Button>
                <Tooltip title={'Audit Logs'} overlayClassName="custom-tooltip">
                  <Button
                    className="bg-transparent block md:hidden hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                    icon={<FileSearchOutlined />}
                    onClick={handleOpenAuditLogs}
                  />
                </Tooltip>
              </>
            )}
            <>
              <Button
                className="bg-transparent hidden md:block hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                onClick={handleOnSave}
                loading={saveLoading}
              >
                {isEditing ? 'Save' : 'Edit'}
              </Button>
              <Tooltip
                title={isEditing ? 'Save' : 'Edit'}
                overlayClassName="custom-tooltip"
              >
                <Button
                  className="bg-transparent block md:hidden hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                  icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                  onClick={handleOnSave}
                  loading={saveLoading}
                />
              </Tooltip>

              {/* edit remarks modal */}
              {/* <RemarksModal
                onApprove={(values) =>
                  handleSavingRemarks(values, 'not yet defined')
                }
                isOpen={isRemarksModalOpen}
                fieldName="edit_remarks"
                fieldPlaceholder="Enter remarks"
                loading={false}
                headerTitle="Remarks"
                description={'Enter remarks for ${placeholder} section'}
                agreeBtnClasses="text-white font-open-sans bg-green-600 px-10"
                agreeText="OK"
              /> */}
            </>
          </Space>

          {/* ..................audit log modal................... */}
          <Modal
            spanWidth="!w-screen sm:!w-[90vw] md:!w-[80vw] lg:!w-[60vw]"
            centered
            isOpen={isAuditLogsOpen}
            header={
              <h3 className="px-4 pt-3 font-bold text-center capitalize text-md font-open-sans">
                {`${title} Logs`}
              </h3>
            }
            footerComponent={
              <div className="flex justify-center flex-1 pb-2">
                <Button
                  className="text-sm font-semibold text-white bg-primary hover:bg-transparent hover:!border-primary hover:!text-primary font-open-sans"
                  onClick={handleCloseAuditLogs}
                >
                  OK
                </Button>
              </div>
            }
          >
            <div className="px-2 py-0 md:px-4">
              <Table
                spanWidth={600}
                columns={columns}
                dataSource={transformedAuditLogs}
                bordered
                loading={experimentLogsLoading}
              />
            </div>
          </Modal>
        </Flex>
      );

      // if is view and is have permission to view audit logs
    } else if (isView && PERMISSIONS.canViewAuditLogs(role)) {
      return (
        <Flex justify="space-between" align="center">
          <label className="text-sm sm:text-base">{title}</label>
          <Button
            className="bg-transparent hidden md:block hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
            icon={<FileSearchOutlined />}
            onClick={handleOpenAuditLogs}
          >
            Audit Logs
          </Button>
          <Tooltip title={'Audit Logs'} overlayClassName="custom-tooltip">
            <Button
              className="bg-transparent block md:hidden hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
              icon={<FileSearchOutlined />}
              onClick={handleOpenAuditLogs}
            />
          </Tooltip>

          {/* ..................audit log modal................... */}
          <Modal
            spanWidth="!w-screen sm:!w-[90vw] md:!w-[80vw] lg:!w-[60vw]"
            centered
            isOpen={isAuditLogsOpen}
            header={
              <h3 className="px-4 pt-3 font-bold text-center capitalize text-md font-open-sans">
                {`${title} Logs`}
              </h3>
            }
            footerComponent={
              <div className="flex justify-center flex-1 pb-2">
                <Button
                  className="text-sm font-semibold text-white bg-primary hover:bg-transparent hover:!border-primary hover:!text-primary font-open-sans"
                  onClick={handleCloseAuditLogs}
                >
                  OK
                </Button>
              </div>
            }
          >
            <div className="px-2 py-0 md:px-4">
              <Table
                spanWidth={600}
                columns={columns}
                dataSource={transformedAuditLogs}
                bordered
                loading={experimentLogsLoading}
              />
            </div>
          </Modal>
        </Flex>
      );
    } else {
      return <span>{title}</span>;
    }
  }
}
