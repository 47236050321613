import { Flex, Spin, Tooltip, Badge, Space } from 'antd';
import Table from 'common/Table';
import { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ClientModal from './ClientModal';
import { openNotification } from 'utilities/notification';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { createClient } from 'features/Admin/redux/slice';

type ListProps = {
  searchClient: string;
  clientsList: any;
  clientsListLoading: boolean;
  handelGetClientsList: any;
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
  addUserLoading: boolean
};

interface payload {
  id: number;
  is_active: boolean;
}

export default function ListView({
  searchClient,
  clientsList,
  clientsListLoading,
  handelGetClientsList,
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM,
  addUserLoading
}: ListProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDataById, setUserDataById] = useState({});

  const dispatch: AppDispatch = useDispatch();

  const handleModalOpen = (record: any) => {
    setUserDataById(record);
    setIsModalOpen(true);
  };
  
  const handleModalClose = () => setIsModalOpen(false);

  const handleDeleteClient = (id: number) => {
    console.log('>>client deleted with id:', id);
    const payload: payload = {
      id: id,
      is_active: false
    };
    dispatch(createClient(payload)).then((res: any) => {
      if (res.payload.success) {
        // onClose();
        // form.resetFields();
        handelGetClientsList();
      }
    });
  };

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Name
        </label>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (text: string, record: any, index: number) => {
        return (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {record.is_new && (
                <Badge.Ribbon
                  placement="start"
                  text="New"
                  className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center ${index === 0 ? '-top-4' : '-top-5'} !-left-4 text-2xs bg-primary`}
                ></Badge.Ribbon>
              )}
              {text}
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Email
        </label>
      ),
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="textStyle">{text}</p>{' '}
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Description
        </label>
      ),
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (text: string) => (
        <Tooltip overlayClassName="custom-tooltip" title={text}>
          <p className="capitalize textStyle">{!text ? 'NA' : text}</p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_: any, record: any) => (
        <Space>
          <Tooltip
            title={'Edit'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <EditOutlined
              onClick={() => handleModalOpen(record)}
              className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
            />
          </Tooltip>
          <Tooltip
            title={'Delete'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <DeleteOutlined
              className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
              onClick={() => {
                openNotification({
                  onApprove: () => handleDeleteClient(record.key),
                  title: 'Deleting Client',
                  subTitle: 'Are you sure you want to delete this client',
                  approveBtnClasses:
                    'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
                  cancelBtnClasses: 'px-10 py-4',
                  approveText: 'Delete',
                  cancelText: 'No',
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const clientList = clientsList?.map((item: any) => {
    return {
      key: item?.id,
      name: item?.client_name,
      email: item?.client_email1,
      description: item?.description,
      client_address: item?.client_address,
      client_email2: item?.client_email2,
      client_phone1: item?.client_phone1,
      client_phone2: item?.client_phone2,
      is_active: item?.is_active,
    };
  });

  return (
    <Spin spinning={clientsListLoading}>
      <Flex className="relative h-full m-2 overflow-auto sticky-footer">
        <Table
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={clientList}
        />
        <ClientModal
          isEdit={true}
          addUserLoading={addUserLoading}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          handelGetClientsList={handelGetClientsList}
          userDataById={userDataById}
        />
      </Flex>
    </Spin>
  );
}
