import { Button, Col, Flex, Form, Row } from 'antd';
import FormInput from 'common/FormInput';
import { useCallback, useEffect, useState } from 'react';
import SolventModal from '../Modals/SolventModal';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { getUomList } from 'features/proposalLeadFlow/projects/redux/slice';
import { debounce } from 'lodash';
import {
  addMaterials,
  getMaterialsList,
} from 'features/sectionHeadFlow/experiments/redux/slice';
import { MATERIAL_TYPES } from '..';
import RemarksModal from 'common/RemarksModal';

type TProps = {
  projectId: string | number;
  isCreate: boolean;
  isView: boolean;
  folderId: string | undefined;
};

export default function SolventOthersTab({
  // form,
  projectId,
  isCreate,
  isView,
  folderId,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const { uomList } = useSelector((state: RootState) => state.project2);
  const [searchTerm, setSearchTerm] = useState('');
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const { addMaterialsLoading, experimentId, isExternalChemist } = useSelector(
    (state: RootState) => state.experiments
  );

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const finalSubmit = (values: any) => {
    const payload = {
      ...values,
      project_id: projectId,
      is_manual_entry: false,
      uom_id: values.uom_master_id,
      type: MATERIAL_TYPES.solvents,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addMaterials(payload)).then((res: any) => {
      setIsRemarksModalOpen(false);
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };

        form.resetFields();
        dispatch(getMaterialsList(listPayload));
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate) {
          finalSubmit({ remark, ...values });
        } else {
          if (isRemarksModalOpen) {
            finalSubmit({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  const renderedUOMList = uomList?.map((uom) => {
    return { label: uom.uom_code, value: uom.id };
  });

  useEffect(() => {
    const payloadUOM = {
      search: searchTerm,
    };
    dispatch(getUomList(payloadUOM));
  }, [searchTerm]);

  const handleUomSearch = useCallback(
    debounce((val: string) => {
      setSearchTerm(val);
    }, 500),
    []
  );

  const onUomSearch = (val: string) => {
    handleUomSearch(val);
  };

  return (
    <Form
      id="solvent_form"
      onFinish={handleSubmit}
      form={form}
      layout="vertical"
    >
      <Row gutter={10}>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            marginBottom="medium"
            name="material_name"
            label={<span className="text-tertiary-dark">Material Name</span>}
            required
            validateMsg="Material Name is required"
            defaultStyle={false}
            placeholder="Enter material name"
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <Row>
            <Col span={16}>
              <FormInput
                marginBottom="medium"
                name="quantity"
                type="number"
                label={<span className="text-tertiary-dark">Quantity (C)</span>}
                inputClasses="p-select font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                defaultStyle={false}
                placeholder="Enter quantity"
                disabled
              />
            </Col>
            <Col span={8}>
              <FormInput
                popupClassName="select-dropdown"
                name="uom_master_id"
                label={<div className="invisible">uom</div>}
                onSearch={onUomSearch}
                type="select"
                defaultStyle={false}
                placeholder="UOM"
                options={renderedUOMList}
                selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                wrapperClasses="rounded-e-md hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark"
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            marginBottom="medium"
            name="wt_wt"
            label={<span className="text-tertiary-dark">wt/Wt (F)</span>}
            defaultStyle={false}
            placeholder="Enter Wt/wt"
            required
            type="number"
            validateMsg="Mol. formula is required"
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            marginBottom="medium"
            name="source"
            label={<span className="text-tertiary-dark">Source</span>}
            defaultStyle={false}
            placeholder="Enter source"
          />
        </Col>
      </Row>
      <Flex wrap gap={10} justify="center" className="m-3">
        <>
          <Button
            onClick={() => form.resetFields()}
            className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
          >
            Clear
          </Button>
          <Button
            type="primary"
            className="text-sm font-semibold bg-transparent border font-open-sans sm:w-auto text-primary border-primary hover:text-white hover:!bg-primary"
            onClick={() => handleSubmit('')}
            loading={addMaterialsLoading}
          >
            Add Values
          </Button>
          <Button
            type="primary"
            className="text-sm font-semibold bg-transparent border sm:w-auto  text-primary border-primary hover:text-white hover:!bg-primary font-open-sans"
            onClick={handleModalOpen}
          >
            Add manual entries
          </Button>
        </>
      </Flex>

      {/* solvent modal for manual entries */}
      <SolventModal
        isExternalChemist={isExternalChemist as boolean}
        folderId={folderId}
        addMaterialsLoading={addMaterialsLoading}
        projectId={projectId}
        renderedUOMList={renderedUOMList}
        onUomSearch={onUomSearch}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        experimentId={experimentId}
        isCreate={isCreate}
        isView={isView}
      />

      {/*change remarks modal */}
      <RemarksModal
        isOpen={isRemarksModalOpen}
        onApprove={(value) => handleSubmit(value)}
        loading={addMaterialsLoading}
        onClose={() => setIsRemarksModalOpen(false)}
      />
    </Form>
  );
}
