import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { message } from 'antd';
import { signin, forgetPass, resetPass } from './api';
import LocalStorage from '../../../utilities/localStorage';
import {
  CHEMIST,
  EXECUTIVE_ACCESS,
  TECHNICAL_LEAD,
} from 'utilities/roleRights';
//import history from '../../../app/history';

interface UserDetails {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface AuthState {
  loading: boolean;
  success: boolean;
  accessToken: string;
  user: UserDetails | null;
}

interface SigninPayload {
  employee_id: string;
  password: string;
}

interface ForgetPassPayload {
  employee_id: string;
}

export interface ApiResponse {
  success: boolean;
  message: string;
  accessToken: string;
  refreshToken: string;
  user_details: UserDetails;
}

const initialState: AuthState = {
  loading: false,
  success: false,
  accessToken: '',
  user: null,
};
// In your redux slice file (e.g., slice.ts)
export interface ResetPassPayload {
  token: string;
  password: string;
}

const actions = {
  LOGIN: 'login/LOGIN',
  FORGOT_PASS: 'login/FORGOT_PASS',
  RESET_PASS: 'login/RESET_PASS',
};

export const login = createAsyncThunk<ApiResponse, SigninPayload>(
  actions.LOGIN,
  async (payload) => {
    const response = await signin(payload);
    if (response?.accessToken) {
      const { accessToken, user_details } = response;
      const newUser = {
        accessToken,
        user_details,
        role:
          user_details.role === EXECUTIVE_ACCESS && user_details.is_tl
            ? EXECUTIVE_ACCESS
            : user_details.role === CHEMIST && user_details.is_tl
              ? TECHNICAL_LEAD
              : user_details.role,
      };
      LocalStorage.setItem('newatom_ELN', JSON.stringify(newUser));
    }

    return response;
  }
);

export const sendPasswordResetLink = createAsyncThunk<
  ApiResponse,
  ForgetPassPayload
>(actions.FORGOT_PASS, async (payload) => {
  const response = await forgetPass(payload);
  return response;
});

export const resetPassword = createAsyncThunk<ApiResponse, ResetPassPayload>(
  actions.RESET_PASS,
  async (payload) => {
    const response = await resetPass(payload);
    return response;
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.accessToken = '';
      state.user = null;
    },
    // Handle logout
    logoutUser: (state) => {
      state.accessToken = '';
      state.user = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<ApiResponse>) => {
        // Access properties directly from action.payload
        const { accessToken, user_details, message: msg } = action.payload;
        state.loading = false;
        state.accessToken = accessToken;
        state.user = user_details;

        if (accessToken) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.rejected, (state, action) => {
        const errorMsg = action.error.message || 'Login failed!';
        state.loading = false;
        message.error(errorMsg);
      });

    // Send password reset link
    builder
      .addCase(sendPasswordResetLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        sendPasswordResetLink.fulfilled,
        (state, action: PayloadAction<ApiResponse>) => {
          state.loading = false;

          message.destroy();
          if (action.payload.success) {
            message.success(action.payload.message);
          } else {
            message.error(action.payload.message);
          }
        }
      )
      .addCase(sendPasswordResetLink.rejected, (state, action) => {
        const errorMsg =
          action.error.message || 'Failed to send password reset link!';
        state.loading = false;
        message.error(errorMsg);
      });

    // Reset password
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        resetPassword.fulfilled,
        (state, action: PayloadAction<ApiResponse>) => {
          state.loading = false;

          if (action.payload.success) {
            message.success(action.payload.message);
            state.success = true;
          } else {
            state.success = false;
            message.error(action.payload.message);
          }
        }
      )
      .addCase(resetPassword.rejected, (state, action) => {
        const errorMsg = action.error.message || 'Failed to reset password!';
        state.loading = false;
        state.success = false;
        message.error(errorMsg);
      });

    // Handle logout
    // builder.addCase(
    //   logoutUser.fulfilled,
    //   (state, action: PayloadAction<ApiResponse>) => {
    //     if (action.payload.success) {
    //       message.success(action.payload.message);
    //       state.accessToken = '';
    //       state.user = null;
    //       localStorage.clear();
    //     } else {
    //       message.error(action.payload.message);
    //     }
    //   }
    // );
  },
});

export const { resetLoginDetails, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
