import React, { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import { useDispatch } from 'react-redux';
import { createUom } from 'features/Admin/redux/slice';
import { useSelector } from 'react-redux';
import RemarksModal from 'common/RemarksModal';

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  addUserLoading: boolean;
  isEdit?: boolean;
  handelGetUomList: () => void;
  userDataById?: any;
};

export default function UOMModal({
  isEdit,
  isOpen,
  onClose,
  addUserLoading,
  handelGetUomList,
  userDataById,
}: TProps) {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { createUomLoading } = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        uom_code: userDataById?.uomCode,
        uom_name: userDataById?.name,
        uom_category: userDataById?.category,
        base_unit: userDataById?.base_unit,
        conversion_factor: userDataById?.conversion_factor,
      });
    }
  }, [userDataById]);

  const handleOnFinish = (values: any) => {
    if (isEdit) {
      setIsRemarksModalOpen(false);
      const payload = {
        ...values,
        id: userDataById.key,
        is_active: true,
        change_remarks: values.remark,
      };
      dispatch(createUom(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelGetUomList();
        }
      });
    } else {
      const payload = {
        ...values,
      };
      dispatch(createUom(payload)).then((res: any) => {
        if (res.payload.success) {
          onClose();
          form.resetFields();
          handelGetUomList();
        }
      });
    }
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values) => {
        if (isEdit) {
          if (isRemarksModalOpen) {
            handleOnFinish({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        } else {
          handleOnFinish({ remark, ...values });
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      headerTitle={isEdit ? 'Edit UOM' : 'Add UOM'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => form.resetFields()}
      btnLoading={addUserLoading}
      agreeText={'Add UOM'}
      footerComponent={
        isEdit ? (
          <Flex justify="center" wrap gap={10} className="pb-6">
            <Button
              onClick={() => {
                onClose();
                form.resetFields();
              }}
              className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="font-semibold text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:border-primary"
              onClick={() => handleSubmit('')}
              loading={addUserLoading}
            >
              Update
            </Button>
          </Flex>
        ) : undefined
      }
    >
      <Form
        id="add_user_modal_form"
        layout="vertical"
        onFinish={handleOnFinish}
        form={form}
      >
        {/* "uom_code": "ltr",
        "uom_name": "Liter",
        "uom_category": "Volume",
        "base_unit": "Milliliter",
        "conversion_factor": 1000 */}
        <div className="!p-4">
          <Row gutter={10}>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="uom_code"
                label={<span className="text-tertiary-dark">UOM Code</span>}
                defaultStyle={false}
                placeholder="Enter uom code"
                required
                validateMsg="Uom code is required"
              />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="uom_name"
                label={<span className="text-tertiary-dark">UOM Name</span>}
                defaultStyle={false}
                placeholder="Enter name"
                required
                validateMsg="UOM name is required"
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="uom_category"
                label={<span className="text-tertiary-dark">UOM Category</span>}
                defaultStyle={false}
                placeholder="Enter UOM category"
                required
                validateMsg="UOM category is required"
              />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <FormInput
                marginBottom="medium"
                name="base_unit"
                label={<span className="text-tertiary-dark">Base Unit</span>}
                defaultStyle={false}
                placeholder="Enter base unit"
                required
                validateMsg="Base Unit is required"
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <FormInput
                marginBottom="medium"
                name="conversion_factor"
                label={
                  <span className="text-tertiary-dark">Conversion Factor</span>
                }
                defaultStyle={false}
                placeholder="Enter conversion factor"
                type="number"
              />
            </Col>
          </Row>

          {/* change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={createUomLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </div>
      </Form>
    </Modal>
  );
}
