import Card from 'common/Card';
import Observation from './Observation';
import ProcedureTable from 'common/ProcedureTable';
import { Button, Divider, Empty, Flex, FormInstance, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import {
  addProcedure,
  getProdecedureList,
  setExperimentId,
} from '../redux/slice';
import { useEffect, useState } from 'react';
import { getLoggedInUser } from 'utilities/helpers';
import ai from 'assets/newIcon/ai-chipset.svg';
import SectionHeader from 'common/SectionHeader';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import { useSelector } from 'react-redux';

const TYPE = {
  reactions: 'reactions',
  work_up: 'work_up',
  isolation: 'isolation',
};

type TProcedureList = {
  id: string | number;
  is_active: boolean;
  experiment_details: string;
  procedure: string;
  type: string;
};

type TList = {
  id: string | number;
  stepNo: number;
  experiment: string;
  observation: string;
  type: string;
  is_Active: boolean;
};

type TProps = {
  form: FormInstance<any>;
  projectId: string | number;
  procedureList: TProcedureList[];
  setStepNos: React.Dispatch<React.SetStateAction<number>>;
  isCreate: boolean;
  isView: boolean;
  experimentId: string | null;
  folderId: string | undefined;
  experimentStatus: string;
};

export default function Procedure({
  form,
  projectId,
  procedureList,
  setStepNos,
  isCreate,
  isView,
  experimentId,
  folderId,
  experimentStatus,
}: TProps) {
  const { role } = getLoggedInUser();
  const [reactionsList, setReactionsList] = useState<TList[]>([]);
  const [workUpList, setWorkUpList] = useState<TList[]>([]);
  const [isolationList, setIsolationList] = useState<TList[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { isExternalChemist } = useSelector(
    (state: RootState) => state.experiments
  );

  const handleSave = () => {
    if (isEditing) {
      console.log('>>saving data');
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleAddingProcedure = (type: string, remark: string) => {
    const values = form.getFieldsValue();

    const experiment = `experiment_${type}`;
    const observation = `observation_${type}`;
    const payload = {
      experiment_details: convertChemicalFormulasToSimpleText(
        values[experiment]
      ),
      procedure: convertChemicalFormulasToSimpleText(values[observation]),
      project_id: projectId,
      type,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: remark,
      section: 'procedure',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addProcedure(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        dispatch(setExperimentId(experiment_id));
        form.setFieldsValue({
          [experiment]: null,
          [observation]: null,
          experiment_id: experiment_id,
        });
        dispatch(getProdecedureList(listPayload));
      }
    });
  };

  const transformProcedureList = (procedureType: string): TList[] => {
    return procedureList
      .filter((reaction) => reaction.type === procedureType)
      .map((reaction: TProcedureList, i): TList => {
        return {
          id: reaction.id,
          is_Active: reaction.is_active,
          experiment: convertTextWithChemicalFormulas(
            reaction.experiment_details
          ),
          observation: convertTextWithChemicalFormulas(reaction.procedure),
          stepNo: i + 1,
          type: reaction.type,
        };
      });
  };

  useEffect(() => {
    setStepNos(reactionsList.length + workUpList.length + isolationList.length);
  }, [reactionsList, workUpList, isolationList]);

  useEffect(() => {
    const reactions = transformProcedureList(TYPE.reactions);
    const workUp = transformProcedureList(TYPE.work_up);
    const isolation = transformProcedureList(TYPE.isolation);
    setReactionsList(reactions);
    setWorkUpList(workUp);
    setIsolationList(isolation);
  }, [procedureList]);

  return (
    <Card
      header={
        <SectionHeader
          isEditing={isEditing}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Procedure"
          experimentId={experimentId}
          customHeader={
            isCreate ? (
              <Flex justify="space-between" align="center">
                <label className="text-sm sm:text-base">Procedure</label>
                <Button
                  className={`bg-transparent !px-2 font-open-sans border-primary text-primary ${procedureList.length ? 'hover:!text-white hover:!bg-primary' : ''}`}
                  icon={<img src={ai} />}
                  disabled={!procedureList.length}
                >
                  Paraphrase
                </Button>
              </Flex>
            ) : null
          }
        />
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <Card
          header={'Reactions'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.reactions}
                isCreate={isCreate}
              />
            )}
            {isView && !reactionsList.length && <Empty className="!my-2" />}

            {reactionsList.map((reaction, i) => {
              return (
                <>
                  <Observation
                    isCreate={isCreate}
                    type={TYPE.reactions}
                    isView={isView}
                    isEditing={isEditing}
                    projectId={projectId}
                    key={reaction.id}
                    stepNo={reaction.stepNo}
                    id={reaction.id}
                    experiment={reaction.experiment}
                    observation={reaction.observation}
                    isExternalChemist={isExternalChemist as boolean}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
        <Card
          header={'Work-up'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.work_up}
                isCreate={isCreate}
              />
            )}
            {isView && !workUpList.length && <Empty className="!my-2" />}

            {workUpList.map((work_up, i) => {
              return (
                <>
                  <Observation
                    type={TYPE.work_up}
                    isCreate={isCreate}
                    isView={isView}
                    isEditing={isEditing}
                    projectId={projectId}
                    key={work_up.id}
                    stepNo={reactionsList.length + work_up.stepNo}
                    id={work_up.id}
                    experiment={work_up.experiment}
                    observation={work_up.observation}
                    isExternalChemist={isExternalChemist as boolean}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
        <Card
          header={'Isolation'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.isolation}
                isCreate={isCreate}
              />
            )}
            {isView && !isolationList.length && <Empty className="!my-2" />}
            {isolationList.map((iso, i) => {
              return (
                <>
                  <Observation
                    type={TYPE.isolation}
                    isEditing={isEditing}
                    isCreate={isCreate}
                    isView={isView}
                    projectId={projectId}
                    key={iso.id}
                    stepNo={
                      reactionsList.length + workUpList.length + iso.stepNo
                    }
                    id={iso.id}
                    experiment={iso.experiment}
                    observation={iso.observation}
                    isExternalChemist={isExternalChemist as boolean}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
      </div>
    </Card>
  );
}
