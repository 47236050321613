import { Spin, Tooltip } from 'antd';
import { AppDispatch } from 'app/store';
import deleteIcon from 'assets/icons/delete.svg';
import { useDispatch } from 'react-redux';
import { addProduct, getProductsList } from '../redux/slice';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { convertTextWithChemicalFormulas } from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';
import Table from 'common/Table';

type TProps = {
  datasource: any;
  projectId: string | number;
  experimentId: string | null;
  isCreate: boolean;
  isView: boolean;
  isEditing: boolean;
  loading: boolean;
  isExternalChemist: boolean;
};

export default function CommentsList({
  datasource,
  projectId,
  experimentId,
  isCreate,
  isView,
  isEditing,
  loading,
  isExternalChemist,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const [idToRemove, setIdToRemove] = useState<number | null>(null);

  const handleDeleteProduct = (id: number | null, remark: string) => {
    const payload = {
      id,
      is_active: false,
      change_remarks: remark,
      section: 'comments',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    dispatch(addProduct(payload)).then((res: any) => {
      if (res?.payload?.success) {
        setIdToRemove(null);
        dispatch(
          getProductsList({
            project_id: projectId,
            experiment_id: experimentId,
          })
        );
      } else {
        setIdToRemove(null);
      }
    });
  };

  const columns = [
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Product Name
        </label>
      ),
      dataIndex: 'product_name',
      key: 'product_name',
      width: '10%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Product Formed
        </label>
      ),
      dataIndex: 'productFormed',
      key: 'product_formed',
      width: '8%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            Yes
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Quantity
        </label>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '8%',
      render: (text: string, record: any) => {
        const concatinatedStr = !text
          ? 'NA'
          : !record.uom_master
            ? text + ' ' + 'NA'
            : parseFloat(text).toFixed(2) + ' ' + record.uom_master?.uom_code;
        return (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
              {concatinatedStr}
            </p>
          </Tooltip>
        );
      },
      //renderColumn,
    },
    // {
    //   title: (
    //     <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
    //       UOM
    //     </label>
    //   ),
    //   dataIndex: 'uom_id',
    //   key: 'uom_id',
    //   width: '8%',
    //   ellipsis: true,
    //   render: (text: string) => (
    //     <Tooltip title={text} overlayClassName="custom-tooltip">
    //       <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
    //         {text}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Purity %
        </label>
      ),
      dataIndex: 'purity',
      key: 'purity',
      width: '8%',
      render: (text: string) => (
        <Tooltip overlayClassName="custom-tooltip" title={text}>
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          MW
        </label>
      ),
      dataIndex: 'mol_weight',
      key: 'mol_weight',
      width: '6%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Mol. Formula
        </label>
      ),
      dataIndex: 'mol_formula',
      key: 'mol_formula',
      width: '8%',
      render: (text: string) => (
        <Tooltip
          title={convertTextWithChemicalFormulas(text)}
          overlayClassName="custom-tooltip"
        >
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {convertTextWithChemicalFormulas(text)}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Actual Yield %
        </label>
      ),
      dataIndex: 'actual_yield',
      key: 'actual_yield',
      width: '8%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: (
        <label className="text-xs font-semibold font-open-sans text-tertiary-filter">
          Th.Yield %
        </label>
      ),
      dataIndex: 'th_yield',
      key: 'th_yield',
      width: '7%',
      render: (text: string) => (
        <Tooltip title={text} overlayClassName="custom-tooltip">
          <p className="text-sm font-open-sans text-tertiary-filter text-transform-capitalize">
            {text}
          </p>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '4%',
      hidden: isView && !isEditing,
      render: (text: string, record: any) => {
        return (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <div className="w-full mx-auto text-center">
              {idToRemove && idToRemove === record.id ? (
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              ) : (
                <>
                  <img
                    src={deleteIcon}
                    className="cursor-pointer hover:opacity-75"
                    onClick={() => {
                      if (isCreate) {
                        handleDeleteProduct(record.id, '');
                      }
                      setIdToRemove(record.id);
                    }}
                  />
                </>
              )}
            </div>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      {/*change remarks modal for deleting a product */}
      <RemarksModal
        isOpen={Boolean(idToRemove) && isView}
        onApprove={(value) => handleDeleteProduct(idToRemove, value)}
        loading={loading}
        onClose={() => setIdToRemove(null)}
      />
      <Table columns={columns} dataSource={datasource} bordered />
    </>
  );
}
