import { Button, Flex, Form } from 'antd';
import FolderDetails from '../components/FolderDetails';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createFolder } from 'features/sectionHeadFlow/redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useEffect, useState } from 'react';
import { TabTitle } from 'utilities/helpers';
import { set } from 'lodash';
//  import TabNavigation from '../components/Demo/TabNavigation';

interface Step {
  reaction_scheme: string;
  smiles_string: string;
  description: string;
}

export default function CreateFolder() {
  TabTitle('Create Folder | NewAtom ELN');
  const [form] = Form.useForm();

  const [selectedTechnicalLead, setSelectedTechnicalLead] = useState<any[]>([]);
  const [selectedProjectTeam, setSelectedProjectTeam] = useState<any[]>([]);
  const [lastSmiles, setLastSmiles] = useState<string>('');
  const [steps, setSteps] = useState<Step[]>([
    { reaction_scheme: '', smiles_string: '', description: '' }, // Tab1 initial state
  ]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0); // Active tab index
  const [newTabCleared, setNewTabCleared] = useState<boolean>(false); // Flag to clear editor on new tab
  const [ketcherSmiles, setKetcherSmiles] = useState<string>(''); // State to track SMILES string
  const [tabClicked, setTabClicked] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);

  let count = 0;

  useEffect(() => {
    if (isSubmitting) {
      setSteps([{ reaction_scheme: '', smiles_string: '', description: ' ' }]);
      setNewTabCleared(true); // Trigger clearing the editor
    }
    if (isClear) {
      setSteps([{ reaction_scheme: '', smiles_string: '', description: ' ' }]);
      setNewTabCleared(true); // Trigger clearing the editor
      setKetcherSmiles('');
      setTabClicked(true);
      setActiveTab(0);
    }
    setIsClear(false);
    setIsCancel(false);
  }, [isSubmitting, isClear]);

  const handleCancel = () => {
    setIsCancel(true);

    // Reset states before navigating
    setSteps([{ reaction_scheme: '', smiles_string: '', description: ' ' }]);
    setNewTabCleared(true); // Trigger clearing the editor
    setKetcherSmiles('');
    setTabClicked(true);
    setActiveTab(0);

    // Delay the navigation slightly to allow state updates
    setTimeout(() => {
      navigate(-1);
    }, 0); // Adjust the timeout as needed, or keep it at 0 for immediate navigation after state updates
  };

  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { technicalLeadList, projectTeamList, createFolderLoading } =
    useSelector((state: RootState) => state.sectionHead);

  if (id === 'undefined') {
    return <Navigate replace to={'/dashboard'} />;
  }

  const transformUserList = (list: any[], role: string = '') => {
    return list.map((item) => {
      count++;
      return {
        ...item,
        chemist_id: item.id,
        sequence: count - 1,
        is_tl: role === 'tl' ? true : false,
        view_access: item.view_access ? false : false,
        edit_access: item.edit_access ? true : true,
        approve_access: item.approve_access ? true : true,
      };
    });
  };

  useEffect(() => {
    return () => {
      count = 0;
    };
  }, []);

  const onValuesChange = (changedVal: any, values: any) => {
    if (changedVal.technical_lead) {
      const selectedUsers = technicalLeadList.filter((user: any) =>
        changedVal.technical_lead.includes(user.id)
      );
      setSelectedTechnicalLead(selectedUsers);
    }

    if (changedVal.project_team) {
      const selectedUsers = projectTeamList.filter((user: any) =>
        changedVal.project_team.includes(user.id)
      );
      setSelectedProjectTeam(selectedUsers);
    }
  };

  const onFolderCreate = (values: any) => {
    if (isSubmitting) {
      const payload = {
        ...values,
        project_id: id,
        steps,
        approvers: [
          ...transformUserList(selectedTechnicalLead, 'tl'),
          ...transformUserList(selectedProjectTeam),
        ],
      };
      // console.log('>>>PAYLOAD::', payload);
      dispatch(createFolder(payload)).then((res: any) => {
        if (res.payload.success) {
          navigate('/projects/' + id, { replace: true });
        }
      });
    }
  };

  return (
    <Flex vertical gap="1.25rem">
      <h1 className="text-2xl font-bold text-primary font-open-sans">
        Create Folder
      </h1>
      <Form
        onFinish={onFolderCreate}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        className="flex flex-col gap-5"
      >
        {/* folder details form */}
        <FolderDetails
          form={form}
          selectedTechnicalLead={selectedTechnicalLead}
          setSelectedTechnicalLead={setSelectedTechnicalLead}
          selectedProjectTeam={selectedProjectTeam}
          setSelectedProjectTeam={setSelectedProjectTeam}
          steps={steps}
          setSteps={setSteps}
          lastSmiles={lastSmiles}
          setLastSmiles={setLastSmiles}
          newTabCleared={newTabCleared}
          setNewTabCleared={setNewTabCleared}
          ketcherSmiles={ketcherSmiles}
          setKetcherSmiles={setKetcherSmiles}
          tabClicked={tabClicked}
          setTabClicked={setTabClicked}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <Flex justify="center" gap={'1rem'} className="mt-4 ">
            <Button
              type="text"
              className="text-primary h-9 font-open-sans"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="reset"
              className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
              onClick={() => {
                setIsClear(true);
              }} // Custom reset handler
            >
              Clear
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={createFolderLoading}
              onClick={() => {
                setIsSubmitting(true); // Allow form submission}
              }} // Custom submission handler
              className="text-white h-9 bg-primary-dark font-open-sans hover:!bg-white hover:!text-primary font-semibold border border-primary-dark"
            >
              Create
            </Button>
          </Flex>
        </FolderDetails>
      </Form>
    </Flex>
  );
}
