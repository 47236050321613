import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}

export const projectCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createProject, payload, true, 'file');
};

export const projectCompoundCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createProjectCompound, payload, true);
};

export const industryTypes = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.industryTypes, payload, true);
};

export const clientDetails = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.clientDetails, payload, true);
};

export const uomDetails = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.uomDetails, payload, true);
};

export const projectCode = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.getProjectCode, payload, true);
};
