import { Button, Flex, Form, message, Spin } from 'antd';
import CompoundDetails from '../components/CompoundDetails';
import { useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { createProjectCompound } from '../redux/slice';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import { PROPOSAL_LEAD } from 'utilities/roleRights';
import {
  TabTitle,
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';

export default function CreateCompound() {
  TabTitle('Create Compound | NewAtom ELN');
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState<string>('');
  const [formattedInput, setFormattedInput] = useState<string>(''); // Formatted input state
  const { createProjectCompoundLoading } = useSelector(
    (state: RootState) => state.project2
  );
  const navigate = useNavigate();
  const { role } = getLoggedInUser();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();

  if (id === 'undefined') {
    return <Navigate replace to={'/dashboard'} />;
  }

  const onClear = (e: any) => {
    setEditorValue('');
  };

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (changedVal.compound_code) {
      setFormattedInput(changedVal.compound_code);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      form.setFieldsValue({
        compound_code: convertTextWithChemicalFormulas(formattedInput),
      });
    }
  };

  const onSubmit = (values: any) => {
    dispatch(
      createProjectCompound({
        ...values,
        compound_code: convertChemicalFormulasToSimpleText(
          values.compound_code
        ),
        project_id: id,
        key_specs: editorValue,
        is_active: true,
      })
    ).then((res: any) => {
      if (res.payload.success) {
        if (role === PROPOSAL_LEAD) {
          navigate('/projects/' + id, { replace: true });
        } else {
          navigate('/folders/' + id, { replace: true });
        }
      }
    });
  };
  return (
    <Flex vertical gap="1.25rem">
      <h1 className="text-2xl font-bold text-primary font-open-sans">
        Create Compound Code
      </h1>
      <Form
        onFinish={onSubmit}
        layout="vertical"
        form={form}
        className="flex flex-col gap-5"
        id="compound-form"
        onValuesChange={handleFormValuesChange}
        onKeyDown={handleKeyDown}
      >
        <Spin spinning={createProjectCompoundLoading}>
          <CompoundDetails
            form={form}
            editorSetValue={setEditorValue}
            editorValue={editorValue}
          >
            {/* compound details form */}
            <Flex justify="center" wrap gap={'1rem'} className="mt-4 ">
              <Button
                type="text"
                className="text-primary h-9 font-open-sans"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                htmlType="reset"
                onClick={onClear}
                className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                disabled={createProjectCompoundLoading}
              >
                Clear
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={createProjectCompoundLoading}
                // className="text-white bg-primary"
                className="text-white h-9 bg-primary-dark font-open-sans hover:!bg-white hover:!text-primary font-semibold border border-primary-dark"
              >
                Create Compound Code
              </Button>
            </Flex>
          </CompoundDetails>
        </Spin>
      </Form>
    </Flex>
  );
}
