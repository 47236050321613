import { Col, Form, Row } from 'antd';
import { AppDispatch } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import {
  addMaterials,
  getMaterialsList,
} from 'features/sectionHeadFlow/experiments/redux/slice';
import { useDispatch } from 'react-redux';
import { MATERIAL_TYPES } from '..';
import { useState } from 'react';
import RemarksModal from 'common/RemarksModal';

type TList = {
  label: string;
  value: string;
};

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  renderedUOMList: TList[];
  onUomSearch: (val: string) => void;
  projectId: string | number;
  addMaterialsLoading: boolean;
  experimentId: string | null;
  folderId: string | undefined;
  isCreate: boolean;
  isView: boolean;
  isExternalChemist: boolean;
};

export default function SolventModal({
  isOpen,
  onClose,
  renderedUOMList,
  onUomSearch,
  projectId,
  addMaterialsLoading,
  experimentId,
  folderId,
  isCreate,
  isView,
  isExternalChemist
}: TProps) {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);

  const handleOnFinish = (values: any) => {
    setIsRemarksModalOpen(false);
    const payload = {
      ...values,
      project_id: projectId,
      is_manual_entry: true,
      uom_id: values.uom_master_id,
      type: MATERIAL_TYPES.solvents,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addMaterials(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        onClose();
        form.resetFields();
        dispatch(getMaterialsList(listPayload));
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate) {
          handleOnFinish({ remark, ...values });
        } else {
          if (isRemarksModalOpen) {
            handleOnFinish({ remark, ...values });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <Form id="solvent_modal_form" onFinish={handleSubmit} form={form}>
      <Modal
        btnLoading={addMaterialsLoading}
        headerTitle="Solvent & Others"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        onAgree={() => form.submit()}
        onCancel={() => form.resetFields()}
      >
        <>
          <div className="px-3">
            <Row gutter={10}>
              <Col span={24}>
                <FormInput
                  marginBottom="medium"
                  name="material_name"
                  label={
                    <span className="text-tertiary-dark">Material Name</span>
                  }
                  required
                  validateMsg="Material Name is required"
                  defaultStyle={false}
                  placeholder="Enter material name"
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 12 }}>
                <Row>
                  <Col span={16}>
                    <FormInput
                      marginBottom="medium"
                      type="number"
                      name="quantity"
                      label={
                        <span className="text-tertiary-dark">Quantity (C)</span>
                      }
                      inputClasses="p-2 font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                      required
                      validateMsg="Quantity is required"
                      defaultStyle={false}
                      placeholder="Enter quantity"
                    />
                  </Col>
                  <Col span={8}>
                    <FormInput
                      popupClassName="select-dropdown"
                      name="uom_master_id"
                      label={<div className="invisible">uom</div>}
                      onSearch={onUomSearch}
                      type="select"
                      defaultStyle={false}
                      placeholder="UOM"
                      options={renderedUOMList}
                      selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                      wrapperClasses="rounded-e-md hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark"
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <FormInput
                  marginBottom="medium"
                  name="wt_wt"
                  label={<span className="text-tertiary-dark">wt/Wt (F)</span>}
                  defaultStyle={false}
                  placeholder="Enter Wt/Wt"
                  required
                  type="number"
                  validateMsg="Mol. formula is required"
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 24 }}>
                <FormInput
                  marginBottom="medium"
                  name="source"
                  label={<span className="text-tertiary-dark">Source</span>}
                  defaultStyle={false}
                  placeholder="Enter source"
                />
              </Col>
            </Row>
          </div>
          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen && isOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={addMaterialsLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </>
      </Modal>
    </Form>
  );
}
