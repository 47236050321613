import {
  Flex,
  Spin,
  Tooltip,
  Badge,
  Space,
  Switch,
  Popover,
  Form,
  Button,
} from 'antd';
import Table from 'common/Table';
import { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TypeOfTestModal from './TypeOfTestModal';
import { openNotification } from 'utilities/notification';
import { AppDispatch } from 'app/store';
import { useDispatch } from 'react-redux';
import { createTypeOfList } from 'features/Admin/redux/slice';
import FormInput from 'common/FormInput';

type ListProps = {
  searchClient: string;
  typeOfList: any;
  typeOfListLoading: boolean;
  handelgetTypeOfList: any;
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
  checkedTab: string;
  createTypeOfListLoading: boolean;
};

interface payload {
  id: number;
  is_active: boolean;
}

export default function ListView({
  searchClient,
  typeOfList,
  typeOfListLoading,
  handelgetTypeOfList,
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM,
  checkedTab,
  createTypeOfListLoading,
}: ListProps) {
  const [idToUpdate, setIdToUpdate] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDataById, setUserDataById] = useState({});
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();

  const handleModalOpen = (record: any) => {
    setUserDataById(record);
    setIsModalOpen(true);
  };

  const handleModalClose = () => setIsModalOpen(false);

  //handle enabling/disabling type of test
  const handleUpdateTestStatus = (status: boolean) => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        const payload = {
          id: idToUpdate,
          is_active: status,
          deactivation_reason: reason,
        };
        dispatch(createTypeOfList(payload)).then((res: any) => {
          if (res?.payload?.success) {
            setIdToUpdate(null);
            form.resetFields();
            handelgetTypeOfList();
          }
        });
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    // const reason = form.getFieldValue(['reason']);
    // const payload = {
    //   id: idToUpdate,
    //   is_active: status,
    //   deactivation_reason: reason,
    // };
    // dispatch(createTypeOfList(payload)).then((res: any) => {
    //   if (res?.payload?.success) {
    //     setIdToUpdate(null);
    //     form.resetFields();
    //     handelgetTypeOfList();
    //   }
    // });
  };

  const handleDeleteClient = (checked: boolean, id: number) => {
    if (checked === false) {
      const payload: payload = {
        id: id,
        is_active: false,
      };
      dispatch(createTypeOfList(payload)).then((res: any) => {
        if (res.payload.success) {
          handelgetTypeOfList();
        }
      });
    } else {
      const payload: payload = {
        id: id,
        is_active: true,
      };
      dispatch(createTypeOfList(payload)).then((res: any) => {
        if (res.payload.success) {
          handelgetTypeOfList();
        }
      });
    }
  };

  //content for disabling the type of test
  const contentForUpdatingStatus = (isActive: boolean) => (
    <Form
      form={form}
      id="type_of_test_updation_form"
      className={`flex flex-col ${!isActive && 'gap-4'}`}
    >
      <div className="flex flex-col gap-2">
        <span className="text-xs font-semibold font-open-sans">
          Do you want to {isActive ? 'disable' : 'enable'} this type of test?
        </span>
        {isActive && (
          <FormInput
            name="reason"
            type="textarea"
            placeholder="Enter deactivation reason"
            required
            rules={[
              {
                required: true,
                message: 'Reason is required',
              },
            ]}
          />
        )}
      </div>
      <div className="flex justify-around ">
        <Button
          size="small"
          className="font-medium text-xs font-open-sans hover:!text-primary border hover:!border-primary"
          onClick={() => setIdToUpdate(null)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          className="font-medium text-xs text-white font-open-sans hover:!text-primary hover:!bg-transparent bg-primary border hover:!border-primary"
          onClick={() => handleUpdateTestStatus(isActive ? false : true)}
          loading={createTypeOfListLoading}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Type of Test
        </label>
      ),
      dataIndex: 'type_of_test',
      key: 'type_of_test',
      width: '40%',
      render: (text: string, record: any, index: number) => {
        return (
          <Tooltip title={text} overlayClassName="custom-tooltip">
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {record.is_new && (
                <Badge.Ribbon
                  placement="start"
                  text="New"
                  className={`absolute bottom-0 h-[0.85rem] !py-0 flex items-center ${index === 0 ? '-top-4' : '-top-5'} !-left-4 text-2xs bg-primary`}
                ></Badge.Ribbon>
              )}
              {text}
            </p>
          </Tooltip>
        );
      },
    },

    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '30%',
      render: (_: any, record: any) => (
        <Space>
          {record.isActive && (
            <Tooltip
              title={'Edit'}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            >
              <EditOutlined
                onClick={() => handleModalOpen(record)}
                className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
              />
            </Tooltip>
          )}
          <Popover
            title=""
            open={idToUpdate === record.key}
            content={contentForUpdatingStatus(record.isActive)}
            trigger={['click']}
            placement="topLeft"
            overlayClassName=" w-1/4"
          >
            <Tooltip
              title={record.isActive ? 'Disable' : 'Enable'}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            >
              <Switch
                size="small"
                value={record.isActive}
                onChange={() => setIdToUpdate(record.key)}
              />
            </Tooltip>
          </Popover>
        </Space>
      ),
    },
  ];

  const clientList = typeOfList?.map((item: any) => {
    return {
      key: item?.id,
      id: item?.id,
      is_active: item?.is_active,
      type_of_test: item?.type_of_test,
      user_id: item?.user_id,
      isActive: item?.is_active,
    };
  });

  return (
    <Spin spinning={typeOfListLoading}>
      <Flex className="relative h-full m-2 overflow-auto sticky-footer">
        <Table
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={clientList}
        />
        <TypeOfTestModal
          isEdit={true}
          addUserLoading={createTypeOfListLoading}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          handelgetTypeOfList={handelgetTypeOfList}
          userDataById={userDataById}
        />
      </Flex>
    </Spin>
  );
}
