import { useEffect } from 'react';
import {
  Breadcrumb,
  Button,
  Divider,
  Flex,
  Space,
  Spin,
  Switch,
  Tabs,
  TabsProps,
  Badge,
} from 'antd';
import ExperimentList from './pages/ExperimentList';
import SearchBar from 'common/Content/searchBar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { getLoggedInUser, STATUS, TabTitle } from 'utilities/helpers';
import {
  ArrowLeftOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { PERMISSIONS } from 'utilities/roleRights';
import {
  getExperimentsList,
  getExperimentTabCounts,
  resetExperimentState,
} from './redux/slice';
import moment from 'moment';
import { getFolderDetails } from '../redux/slice';
import Label from 'common/Label';
import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import download_icon from 'assets/icons/download_icon_active.svg';
import CompoundList from 'features/proposalLeadFlow/projects/components/CompoundList';
import ColumnDropdownFilter from 'common/ColumnDropdownFilter';
import folderIcon from 'assets/icons/new_folder_breadcrumb_active.svg';
import projectIcon from 'assets/icons/projects_breadcrumb.svg';
import projectActiveIcon from 'assets/icons/projects_breadcrumb_active.svg';
import IconLink from 'common/IconLink';

export interface TProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  folderList?: any[];
  pagination: any;
  loading: boolean;
  setFromDate?: React.Dispatch<React.SetStateAction<any>>;
  setToDate?: React.Dispatch<React.SetStateAction<any>>;
}

const getActiveCategory = (index: string): string => {
  switch (index) {
    case '0':
      return 'all';
    case '1':
      return STATUS.draft;
    case '2':
      return STATUS.pending;
    case '3':
      return STATUS.approved;
    case '4':
      return STATUS.rejected;
    default:
      return 'all';
  }
};

const PER_PAGE_ITEM = 15;

export default function Experiments() {
  TabTitle('Experiments | NewAtom ELN');
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('0');
  const { folder_id, project_id } = useParams();
  const [showMyExperiments, setShowMyExperiments] = useState(false);
  const { projectDetailData, getprojectDetailLoading } = useSelector(
    (state: RootState) => state.project
  );
  // const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    experimentList,
    pagination,
    experimentListLoading,
    experimentTabCountsLoading,
    experimentTabCounts,
  } = useSelector((state: RootState) => state.experiments);

  const { folderDetails } = useSelector(
    (state: RootState) => state.sectionHead
  );

  const folderListData = experimentList?.length
    ? experimentList?.map((item: any) => {
        const dayDiff = moment().diff(item?.start_date, 'days');
        return {
          key: item.id,
          projectId: item.project_id,
          proposalCode: item.project?.proposal_code,
          experimentId: item.id,
          stepNo: item.step_number,
          startDate: item?.start_date,
          authors: item?.authors,
          endDate: item?.end_date,
          objective_of_reaction: item?.objective_of_reaction,
          status: item?.status,
          isOld: dayDiff > 3 && item?.status === STATUS.pending ? true : false,
        };
      })
    : [];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchProject, setSearchProject] = useState<string>('');
  const items: TabsProps['items'] = [
    {
      key: '0',
      label: (
        <Space>
          <Badge
            showZero
            overflowCount={999}
            count={experimentTabCounts?.all}
            style={{
              color: '#d46b08', //'#434343',
              background: '#fff7e6', //'#d9d9d9',
              borderColor: '#ffd591', // '#8c8c8c',
            }}
            // color="gray"
          >
            <span className="pt-10 pr-3">All</span>
          </Badge>
        </Space>
      ),
      children: (
        <ExperimentList
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          folderList={folderListData}
          loading={experimentListLoading}
        />
      ),
    },
    {
      key: '1',
      label: (
        <Space>
          <Badge
            showZero
            overflowCount={999}
            count={experimentTabCounts?.draft}
            style={{
              color: '#000000E0',
              background: '#fafafa',
              borderColor: '#d9d9d9',
            }}

            // color="#bfbfbf"
          >
            <span className="pt-10 pr-3">In Progress</span>
          </Badge>
        </Space>
      ),
      children: (
        <ExperimentList
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          folderList={folderListData}
          loading={experimentListLoading}
        />
      ),
    },
    {
      key: '2',
      label: (
        <Space>
          <Badge
            showZero
            overflowCount={999}
            count={experimentTabCounts?.pending}
            // color="blue"
            style={{
              color: '#0958d9',
              background: '#e6f4ff',
              borderColor: '#91caff',
            }}
          >
            <span className="pt-10 pr-3">Sent for Approval</span>
          </Badge>
        </Space>
      ),
      children: (
        <ExperimentList
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          folderList={folderListData}
          loading={experimentListLoading}
        />
      ),
    },
    {
      key: '3',
      label: (
        <Space>
          <Badge
            showZero
            overflowCount={999}
            count={experimentTabCounts?.approved}
            // color="green"
            style={{
              color: '#389e0d',
              background: '#f6ffed',
              borderColor: '#b7eb8f',
            }}
          >
            <span className="pt-10 pr-3">Approved</span>
          </Badge>
        </Space>
      ),
      children: (
        <ExperimentList
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          folderList={folderListData}
          loading={experimentListLoading}
        />
      ),
    },
    {
      key: '4',
      label: (
        <Space>
          <Badge
            showZero
            overflowCount={999}
            count={experimentTabCounts?.rejected}
            // color="red"
            style={{
              color: '#cf1322',
              background: '#fff1f0',
              borderColor: '#ffa39e',
            }}
          >
            <span className="pt-10 pr-3">Returned</span>
          </Badge>
        </Space>
      ),
      children: (
        <ExperimentList
          setFromDate={setFromDate}
          setToDate={setToDate}
          pagination={pagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          folderList={folderListData}
          loading={experimentListLoading}
        />
      ),
    },
  ];

  //fetching experiment list
  useEffect(() => {
    const payload = {
      page: currentPage,
      perPage: PER_PAGE_ITEM,
      search: searchProject,
      project_id,
      folder_id,
      category: getActiveCategory(currentTab),
      my_experiments: showMyExperiments,
      from_date: fromDate,
      to_date: toDate,
    };

    const delayDebounceFn = setTimeout(() => {
      dispatch(getExperimentsList(payload));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    searchProject,
    currentTab,
    currentPage,
    project_id,
    folder_id,
    showMyExperiments,
    fromDate,
    toDate,
  ]);

  //fetching experiments tab counts
  useEffect(() => {
    dispatch(
      getExperimentTabCounts({
        project_id,
        folder_id,
        my_experiments: showMyExperiments,
      })
    );
  }, [showMyExperiments]);

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchProject(value);
    }, 500),
    []
  );
  const handleSearchChange = (value: string) => {
    handleInputChange(value);
  };

  //fetching experiment details
  useEffect(() => {
    dispatch(getFolderDetails({ id: folder_id }));
  }, [folder_id]);

  //get project details
  useEffect(() => {
    if (!Object.keys(projectDetailData).length) {
      const payload = {
        id: Number(project_id),
      };
      dispatch(getprojectDetail(payload));
    }
  }, []);

  const { role } = getLoggedInUser();

  const compoundCodes = projectDetailData?.project_compounds?.map(
    (item: any) => {
      return {
        id: item.id,
        compoundCode: item.compound_code,
        // aliasCode: item.alias_code,
        quantityRequested: item.requested_quantity,
        purityRequested: item.requested_purity,
        key_specs: item.key_specs,
      };
    }
  );

  const collapseContent = (
    <Flex vertical gap={20}>
      <div className="flex flex-col gap-3 p-1">
        <Label
          description={projectDetailData?.proposal_code}
          label={'Proposal Code'}
        />
        <Label
          description={
            <span className="capitalize text-transform:">
              {projectDetailData?.industry_type?.industry_type}
            </span>
          }
          label={'Industry type'}
        />
        <Label
          description={
            // <span className="text-transform: none">
            projectDetailData?.project_description
            // </span>
          }
          label={'Project Description'}
          containerClasses="flex flex-col items-start w-full gap-1 sm:flex-row sm:items-start sm:gap-6 justify-start"
        />
        <Label
          description={
            <span className="capitalize text-transform:">
              {projectDetailData?.section_heads?.length > 0
                ? projectDetailData?.section_heads
                    .map((user: any) => {
                      return user.section_head_user?.display_name;
                    })
                    .join(', ')
                : ''}
            </span>
          }
          label={'Section Head'}
        />
        <Label
          description={
            projectDetailData?.documents?.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {projectDetailData?.documents?.map(
                  (item: any, index: number) => (
                    <div key={item.id}>
                      <Button
                        type="link"
                        // onClick={() => {
                        //   handleFetchDocument(item?.fileName);
                        // }}

                        className="border rounded-lg text-primary-dark border-tertiary-highlight-200 hover:opacity-75"
                      >
                        <a
                          href={item?.presignedUrl}
                          download={item?.fileName}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex items-center gap-2">
                            <span className="text-xs font-semibold font-open-sans">
                              {item?.originalName}
                            </span>
                            <img src={download_icon} alt="download_icon" />
                          </div>
                        </a>
                      </Button>
                    </div>
                  )
                )}
              </div>
            ) : (
              'NA'
            )
          }
          label={'Attachments'}
          containerClasses="flex flex-col items-start w-full gap-1 sm:flex-row sm:items-start sm:gap-6 justify-start"
        />
      </div>
      {PERMISSIONS.canReadCompound(role) && (
        <div>
          <div className="flex items-center justify-between px-1 text-sm font-bold text-primary sm:text-base font-open-sans">
            Compounds
          </div>
          <div className="px-1 pb-4">
            <CompoundList
              role={role}
              compoundCodes={compoundCodes}
              id={projectDetailData.id}
            />
          </div>
        </div>
      )}
    </Flex>
  );

  return (
    <Flex vertical gap={'1.25rem'}>
      <div className="font-semibold text-tertiary-dark font-open-sans">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: (
                <IconLink
                  classes="w-[16px]"
                  activeIcon={projectActiveIcon}
                  icon={projectIcon}
                  path={`/folders/${project_id}`}
                  label={folderDetails?.project?.project_code}
                />
              ),
            },
            {
              title: (
                <span className="flex gap-1 text-[1.12rem] font-open-sans text-primary">
                  <img src={folderIcon} className="w-[12px]" />
                  {folderDetails?.folder_route?.route +
                    folderDetails?.folder_number}
                </span>
              ),
            },
          ]}
        />
      </div>
      {/* <Collapse
        size="small"
        expandIconPosition="end"
        items={[
          {
            key: '1',
            className: '!bg-secondary-filter overflow-hidden !rounded-lg',
            label: (
              <h1 className="flex items-center gap-1 text-base font-semibold text-tertiary-dark font-open-sans">
                <Link
                  to={`/folders/${project_id}`}
                  className=" hover:!text-primary"
                >
                  {folderDetails?.project?.project_code}
                </Link>{' '}
                <span>/</span>
                <span className="cursor-not-allowed ">
                  {folderDetails?.folder_route?.route +
                    folderDetails?.folder_number}
                </span>
              </h1>
            ),
            children: collapseContent,
          },
        ]}
      /> */}

      <Flex vertical className="h-full shadow-equal-sides rounded-xl">
        <Spin spinning={false}>
          <Flex
            justify="space-between"
            align="center"
            className="px-6 py-4 rounded-t-xl bg-secondary-filter font-open-sans"
          >
            <h2 className="text-lg font-bold md:text-lg lg:text-[1.25rem] md text-primary-dark">
              Experiments
            </h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                alignContent: 'center',
                gap: '1rem',
              }}
            >
              <SearchBar
                justify="start"
                align="middle"
                gutter={32}
                inputProps={{
                  placeholder: 'Search by experiment id or step number',
                }}
                filters={false}
                onChange={(e: any) => handleSearchChange(e.target.value)}
              />
              {PERMISSIONS.canCreateExperiment(role) && (
                <Button
                  size="small"
                  type="text"
                  icon={<PlusOutlined />}
                  className="font-open-sans h-9 text-primary border border-primary hover:!text-white hover:!bg-primary rounded-md"
                  onClick={() => {
                    dispatch(resetExperimentState());
                    navigate('create');
                  }}
                >
                  Create Experiment
                </Button>
              )}
              <Button
                size="small"
                type="text"
                icon={<ArrowLeftOutlined />}
                className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                onClick={() => navigate(-1)}
              >
                Go back
              </Button>
            </div>
          </Flex>

          {/* navigation tabs */}
          <Flex className="relative h-full m-2 overflow-auto sticky-footer">
            <Tabs
              size="small"
              className="!p-0 !m-0 bg-white font-open-sans !rounded-md overflow-hidden"
              tabBarStyle={{ margin: '0px 0px 0px 0.6rem', padding: 0 }}
              defaultActiveKey="0"
              items={items}
              tabBarExtraContent={
                <Space size={10}>
                  <div className="flex items-center gap-1 mr-2">
                    <span className="text-xs font-semibold font-open-sans shrink-0 text-pretty text-primary">
                      Show my experiments :
                    </span>
                    <Switch
                      size="small"
                      className=""
                      onChange={setShowMyExperiments}
                      value={showMyExperiments}
                    />
                  </div>
                  {/* <Divider className="h-4 !p-0 !m-0 w-[1px] bg-red-500" />
                  <ColumnDropdownFilter
                    type="number"
                    setValue={setItemsPerPage}
                  /> */}
                </Space>
              }
              onChange={(tab: any) => {
                setCurrentTab(tab);
                setCurrentPage(1);
              }}
              tabBarGutter={4}
            />
          </Flex>
        </Spin>
      </Flex>
    </Flex>
  );
}
