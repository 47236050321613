import { Button, Col, Flex, Form } from 'antd';
import logo from 'assets/icons/logo_new.svg';
import logo_full from 'assets/icons/logo_full_new.svg';
import FormInput from 'common/FormInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { useEffect } from 'react';
import { ResetPassPayload, resetPassword } from '../redux/slice';
import { TabTitle } from 'utilities/helpers';

interface Payload {
  password: string;
  confirmPassword: string;
}

const ResetPassword: React.FC = () => {
  TabTitle('Reset Password | NewAtom ELN');
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm();

  const { success, loading } = useSelector((state: RootState) => state.login);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/', { replace: true });
    }
  }, [token, navigate]);

  const handleResetPassword = (values: Payload) => {
    if (!values.password || !values.confirmPassword) return;

    const resetPayload: ResetPassPayload = {
      token: token ? token : '',
      password: values.password,
    };

    dispatch(resetPassword(resetPayload));
  };

  useEffect(() => {
    if (success) {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
  }, [success, navigate]);

  return (
    <div className="relative h-screen">
      <div className="w-full h-full bg-center bg-cover login-bg-filter md:w-4/5 lg:w-5/6"></div>
      <Flex
        className="absolute -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 right-1/2 md:right-0 left-1/2 md:left-auto md:translate-x-0 md:-translate-y-1/2 rounded-3xl md:rounded-s-[6rem] py-12 md:py-12 xl:py-16 px-8 md:px-12 lg:px-16 xl:px-20 w-[90vw] sm:w-[60vw] md:w-[40vw]"
        vertical
        gap={'2rem'}
      >
        {/* <img
          alt="logo"
          src={logo}
          className="w-40 mx-auto md:mx-0 md:w-48 xl:w-64"
        /> */}
        <div className="flex items-center justify-center gap-2 px-2 md:justify-center">
          <img alt="logo" src={logo} className="w-[10%] sm:w-[12%]" />
          <img alt="logo full" src={logo_full} className="w-[25%] md:w-[40%]" />
        </div>
        <Form form={form} layout="vertical" onFinish={handleResetPassword}>
          <h2 className="mb-4 text-lg font-bold font-open-sans md:text-xl text-primary-filter">
            Reset Password
          </h2>
          <Flex vertical>
            <Col span={24}>
              <FormInput
                hideColon
                label="New Password"
                name="password"
                validateMsg="Password is required"
                placeholder="Enter new password"
                required
              />
            </Col>
            <Col span={24}>
              <FormInput
                hideColon
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Confirm Password is required',
                  },
                  {
                    validator(
                      rule: any,
                      value: string | undefined,
                      callback: (msg?: string) => void
                    ) {
                      const password = form.getFieldValue('password');
                      if (value && value !== password) {
                        callback('Password and confirm password should match');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                placeholder="Enter confirm password"
              />
            </Col>
          </Flex>
          <Form.Item className="mt-4">
            <Flex flex={1}>
              <Button
                htmlType="submit"
                size="large"
                className="flex-1 text-sm font-bold text-white font-open-sans bg-primary lg:text-base"
                loading={loading}
              >
                Submit
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </div>
  );
};

export default ResetPassword;
