import React, { useState, useCallback } from 'react';
import KetcherStructEditor from './KetcherStructEditor';
import { Row, Col, Button, Tag } from 'antd';
import Description from './Description';
import { TProps } from '../components/FolderDetails';

interface Step {
  reaction_scheme: string;
  smiles_string: string;
  description: string;
}

type TStepsProps = {
  steps: any[];
  setSteps: React.Dispatch<React.SetStateAction<any[]>>;
  handleAddStep: () => void;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  newTabCleared: boolean;
  setNewTabCleared: React.Dispatch<React.SetStateAction<boolean>>;
  ketcherSmiles: string;
  setKetcherSmiles: React.Dispatch<React.SetStateAction<string>>;
  tabClicked: boolean;
  setTabClicked: React.Dispatch<React.SetStateAction<boolean>>;
  lastSmiles: string;
  setLastSmiles: React.Dispatch<React.SetStateAction<string>>;
};

const KetcherTabs = ({
  steps,
  setSteps,
  handleAddStep,
  activeTab,
  setActiveTab,
  newTabCleared,
  setNewTabCleared,
  ketcherSmiles,
  setKetcherSmiles,
  tabClicked,
  setTabClicked,
  lastSmiles,
  setLastSmiles,
}: TStepsProps) => {
  //   const [activeTab, setActiveTab] = useState<number>(0); // Active tab index
  //   const [steps, setSteps] = useState<Step[]>([
  //     { reaction_scheme: '', smiles_string: '', description: '' }, // Tab1 initial state
  //   ]);
  //   const [newTabCleared, setNewTabCleared] = useState<boolean>(false); // Flag to clear editor on new tab
  //   const [ketcherSmiles, setKetcherSmiles] = useState<string>(''); // State to track SMILES string
  //   const [tabClicked, setTabClicked] = useState<boolean>(false);

  // Function to add a new tab and set it as active
  //   const handleAddStep = () => {
  //     const currentStep = steps[activeTab];
  //     // Save current state
  //     const updatedSteps = steps.map((step, i) =>
  //       i === activeTab ? { ...step, smiles_string: ketcherSmiles } : step
  //     );

  //     // Add new step and set it as active
  //     setSteps([
  //       ...updatedSteps,
  //       { reaction_scheme: '', smiles_string: '', description: '' },
  //     ]);
  //     setActiveTab(steps.length); // Set the newly added tab as active
  //     setNewTabCleared(true); // Trigger clearing the editor
  //     setTabClicked(true);
  //   };
  const handleTab = () => {
    setTabClicked(true);
  };

  // Function to handle input field change for the active tab
  const handleInputChange = (field: keyof Step, value: string) => {
    const updatedSteps = steps.map((step, i) =>
      i === activeTab ? { ...step, [field]: value } : step
    );
    setSteps(updatedSteps);
  };

  // Callback to update the SMILES string from Ketcher
  const handleSmilesChange = useCallback(
    (smiles: string) => {
      setKetcherSmiles(smiles);
      const updatedSteps = steps.map((step, i) =>
        i === activeTab ? { ...step, smiles_string: smiles } : step
      );
      setSteps(updatedSteps);
    },
    [activeTab, steps]
  );
  
  return (
    <div>
      <div className="mt-1">
        <span className="text-xs font-semibold lg:text-sm text-tertiary-dark font-open-sans !mr-2">
          Reaction Scheme
        </span>
        {/* Navigation Tabs */}
        {steps?.map((_, index) => (
          <Tag
            key={index}
            onClick={() => {
              setActiveTab(index);
              setNewTabCleared(false); // Stop clearing editor on tab switch
              handleTab();
            }}
            style={{
              backgroundColor: activeTab === index ? '#0044D3' : '#f1f1f1',
              color: activeTab === index ? 'white' : 'black',
              marginRight: '10px',
            }}
            className="text-['9px'] font-semibold lg:text-['9px']  font-open-sans "
          >
            Step {index + 1}
          </Tag>
        ))}
      </div>

      <div>
        {/* Render the KetcherDemo component for the active tab */}

        <KetcherStructEditor
          onSmilesChange={handleSmilesChange}
          moleculeToLoad={steps[activeTab]?.smiles_string} // Load the molecule for the active tab
          clearEditor={newTabCleared} // Clear editor when adding a new tab
          tabClicked={tabClicked}
          setTabClicked={setTabClicked}
          setNewTabCleared={setNewTabCleared}
          lastSmiles={lastSmiles}
          setLastSmiles={setLastSmiles}
        />
      </div>

      <Row>
        <Col span={24}>
          <Description
            step={steps[activeTab]}
            onInputChange={(field, value) => handleInputChange(field, value)}
          />
        </Col>
      </Row>
      {/* For debugging, displaying steps */}
      {/* <div>
        <h4>Steps Data:</h4>
        <pre>{JSON.stringify(steps, null, 2)}</pre>
      </div> */}
    </div>
  );
};

export default KetcherTabs;
